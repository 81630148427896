import { useQuery } from '@tanstack/react-query';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../components/utils/UserContext';
import { useUserLevel } from '../../extraStudy/hooks/getUserLevel';
import { getLevelKanjiPercentage } from './calculateKanjiCoverage';

const DIFFICULTY_ORDER = {
    'Easy': 1,
    'Medium': 2,
    'Hard': 3,
    'Not Available': 4  // Items with unknown difficulty will be sorted last
};

export const useCatalogFetch = () => {
    const navigate = useNavigate();
    const { user } = useUser();
    const { data: userLevel } = useUserLevel(user?.uid);

    return useQuery({
        queryKey: ['catalog', userLevel],
        queryFn: async () => {
            try {
                const db = getFirestore();
                const contentCollection = collection(db, 'immersionContent');
                const snapshot = await getDocs(contentCollection);

                if (snapshot.empty) {
                    return [];
                }

                const catalogData = snapshot.docs.map(doc => {
                    const data = doc.data();
                    return {
                        id: doc.id,
                        active: data.active || false,
                        title: data.title || doc.id,
                        nihongoTitle: data.nihongoTitle || doc.id,
                        longTitle: data.longTitle || '',
                        ...data,
                        genre: data.genre || [],
                        difficulty: data.difficulty || 'Not Available',
                        averageRating: data.averageRating || 0,
                        totalReaders: data.totalReaders || 0,
                        uniqueKanjiCount: data.uniqueKanjiCount || 0,
                        characterCount: data.characterCount || 0,
                        authorsNote: data.authorsNote || '',
                        description: data.description || 'No description available.',
                        estimatedJLPT: data.estimatedJLPT || 'Not Available',
                        series: data.series || 1,
                        levelKanjiPercentage: getLevelKanjiPercentage(
                            data.onikanjiLevelToContentKanji,
                            userLevel
                        )
                    };
                });

                // Filter for active titles only and then sort
                const filteredData = catalogData.filter(item => item.active === true);
                const sortedData = filteredData.sort((a, b) => {
                    const difficultyA = DIFFICULTY_ORDER[a.difficulty] || DIFFICULTY_ORDER['Not Available'];
                    const difficultyB = DIFFICULTY_ORDER[b.difficulty] || DIFFICULTY_ORDER['Not Available'];
                    return difficultyA - difficultyB;
                });

                return sortedData;

            } catch (error) {
                console.error('Catalog fetch error:', error);
                navigate('/error');
                throw error;
            }
        },
        staleTime: 5 * 60 * 1000,
        cacheTime: 30 * 60 * 1000,
        retry: 2,
        refetchOnWindowFocus: false,
        refetchOnMount: true,
        refetchOnReconnect: false,
        enabled: !!userLevel // Only run query when userLevel is available
    });
};
