import React from 'react';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

export const PremadeListKanjiDisplay = ({ premadeListKanji, isKanjiLoading, selectedPremadeList }) => {
  if (isKanjiLoading) {
    return (
      <div className="flex justify-center items-center h-full">
        <ClipLoader color="#014156" className="mr-2" />
      </div>
    );
  }

  if (!selectedPremadeList) {
    return <p className="text-gray-600">Please select a premade list to preview kanji</p>;
  }

  if (!premadeListKanji || premadeListKanji.length === 0) {
    return <p className="text-gray-600">No kanji found in this premade list</p>;
  }

  return (
    <div className="max-w-screen-lg w-full mx-auto">
      <div className="text-lg mb-4">
        Selected {premadeListKanji.length} kanji from: {selectedPremadeList.title}
        {selectedPremadeList.actScene && 
          ` (Act ${selectedPremadeList.actScene.act}, Scene ${selectedPremadeList.actScene.scene})`}
      </div>
      <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 gap-2 mt-6">
        {premadeListKanji.slice(0, 20).map(kanji => (
          <Link
            to={`/kanji/${encodeURIComponent(kanji.kanji)}`}
            key={kanji.kanji}
            className="flex flex-col items-center p-2 bg-white border rounded-lg shadow hover:bg-darkBlueColor hover:text-white transition duration-300"
          >
            <div className="text-3xl font-bold mb-1">
              {kanji.kanji}
            </div>
            <div className="text-sm text-ellipsis overflow-hidden w-full text-center">
              {kanji.primaryMeaning ? 
                (kanji.primaryMeaning.length <= 12 ? 
                  kanji.primaryMeaning : 
                  kanji.primaryMeaning.substring(0, 10) + '...') : 
                'Custom Kanji'}
            </div>
          </Link>
        ))}
        {premadeListKanji.length > 20 && (
          <div className="flex flex-col items-center p-2 bg-white border rounded-lg shadow">
            <div className="text-lg text-gray-600">+{premadeListKanji.length - 20} more</div>
          </div>
        )}
      </div>
    </div>
  );
};