import { useQuery } from '@tanstack/react-query';
import { getFirestore, collection, query, getDocs, getDoc, doc, collectionGroup, where } from 'firebase/firestore';
import { useUser } from '../../components/utils/UserContext';

export const usePremadeLists = (options = {}) => {
  const { user } = useUser();
  
  return useQuery({
    queryKey: ['premadeLists', user?.uid],
    queryFn: async () => {
      const db = getFirestore();
      const premadeLists = [];
      
      try {
        // Step 1: List all curriculum documents to discover what's available
        const curriculumCollectionRef = collection(db, 'customCurriculum');
        const curriculumSnapshot = await getDocs(curriculumCollectionRef);
        
        // Step 2: Try to access each curriculum document individually
        for (const curriculumDoc of curriculumSnapshot.docs) {
          const curriculumId = curriculumDoc.id;
          
          try {
            // Attempt to fetch the document directly to check permissions
            const docRef = doc(db, 'customCurriculum', curriculumId);
            const docSnap = await getDoc(docRef);
            
            if (!docSnap.exists()) {
              continue; // Skip to next document
            }
            
            const curriculumData = docSnap.data();
            
            // Now that we have confirmed access, fetch the subcollections
            try {
              // Get the allKanji subcollection to count kanji and extract act/scene info
              const allKanjiCollectionRef = collection(db, 'customCurriculum', curriculumId, 'allKanji');
              const allKanjiSnapshot = await getDocs(allKanjiCollectionRef);
              
              // Get the reviewQuestionsCollection to find kanji documents
              const reviewQuestionsRef = collection(db, 'customCurriculum', curriculumId, 'reviewQuestionsCollection');
              const reviewQuestionsSnapshot = await getDocs(reviewQuestionsRef);
              
              // Count the total number of generated questions across all kanji
              let totalQuestionCount = 0;
              
              // For each kanji in the reviewQuestionsCollection, count its generatedQuestions
              for (const kanjiDoc of reviewQuestionsSnapshot.docs) {
                const generatedQuestionsRef = collection(
                  db, 
                  'customCurriculum', 
                  curriculumId, 
                  'reviewQuestionsCollection', 
                  kanjiDoc.id, 
                  'generatedQuestions'
                );
                
                const generatedQuestionsSnapshot = await getDocs(generatedQuestionsRef);
                totalQuestionCount += generatedQuestionsSnapshot.size;
              }
              
              // Extract act and scene info from allKanji documents
              const actSceneMap = new Map(); // Map to track unique act/scene combinations
              
              for (const kanjiDoc of allKanjiSnapshot.docs) {
                const kanjiData = kanjiDoc.data();
                
                // Only process if act and scene are present
                if (kanjiData.act !== undefined && kanjiData.scene !== undefined) {
                  const key = `${kanjiData.act}-${kanjiData.scene}`;
                  
                  if (!actSceneMap.has(key)) {
                    actSceneMap.set(key, {
                      act: kanjiData.act,
                      scene: kanjiData.scene,
                      kanjiCount: 1
                    });
                  } else {
                    // Increment kanji count for this act/scene
                    const existing = actSceneMap.get(key);
                    existing.kanjiCount++;
                    actSceneMap.set(key, existing);
                  }
                }
              }
              
              // Convert the map to an array of act/scene combinations
              const actScenes = Array.from(actSceneMap.values());
              
              // Sort by act and scene
              actScenes.sort((a, b) => {
                if (a.act !== b.act) return a.act - b.act;
                return a.scene - b.scene;
              });
              
              // Create a separate list object for each act/scene combination
              for (const actScene of actScenes) {
                const listTitle = curriculumData.displayName || curriculumId;
                
                const listObject = {
                  id: curriculumId,
                  title: `${listTitle} - Act ${actScene.act} Scene ${actScene.scene}`,
                  kanjiCount: actScene.kanjiCount,
                  questionCount: totalQuestionCount,
                  actScene: {
                    act: actScene.act,
                    scene: actScene.scene
                  }
                };
                
                premadeLists.push(listObject);
              }
              
              // If no act/scene combinations were found, create a default list
              if (actScenes.length === 0) {
                const listObject = {
                  id: curriculumId,
                  title: curriculumData.displayName || curriculumId,
                  kanjiCount: allKanjiSnapshot.size,
                  questionCount: totalQuestionCount
                };
                
                premadeLists.push(listObject);
              }
            } catch (subcollectionError) {
              // If there's an error with subcollections, skip this curriculum
              continue;
            }
          } catch (accessError) {
            // This is expected - the user doesn't have access to this curriculum
            continue;
          }
        }
        
        return premadeLists;
        
      } catch (error) {
        // Return empty array on error
        return [];
      }
    },
    staleTime: 5 * 60 * 1000, // Cache for 5 minutes
    retry: 1, // Reduce retries to avoid excessive error logs
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    ...options
  });
};

// Modified hook to fetch the kanji for a specific premade list and act/scene
export const usePremadeListKanji = (listId, options = {}) => {
  const actScene = options.actScene || null;
  
  return useQuery({
    queryKey: ['premadeListKanji', listId, actScene?.act, actScene?.scene],
    queryFn: async () => {
      if (!listId) {
        return [];
      }
      
      try {
        const db = getFirestore();
        
        let kanjiQuery;
        if (actScene && actScene.act !== undefined && actScene.scene !== undefined) {
          // Query with act and scene filters
          kanjiQuery = query(
            collection(db, 'customCurriculum', listId, 'allKanji'),
            where('act', '==', actScene.act),
            where('scene', '==', actScene.scene)
          );
        } else {
          // Query all kanji in the collection
          kanjiQuery = collection(db, 'customCurriculum', listId, 'allKanji');
        }
        
        const snapshot = await getDocs(kanjiQuery);
        
        const kanjiList = snapshot.docs.map(doc => ({
          kanji: doc.id, // The document ID is the kanji character
          ...doc.data()
        }));
        
        return kanjiList;
      } catch (error) {
        return [];
      }
    },
    enabled: !!listId, // Only run the query if we have a listId
    staleTime: 5 * 60 * 1000,
    retry: 2,
    refetchOnWindowFocus: false,
    ...options
  });
};