import { useQuery } from '@tanstack/react-query';
import { getFirestore, doc, collection, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

export const useCriticalKanji = (uid) => {
    const navigate = useNavigate();

    return useQuery({
      queryKey: ['criticalKanji', uid],
      queryFn: async () => {
        try {
          // Validate UID
          if (!uid || typeof uid !== 'string' || uid.length < 1) {
            throw new Error('Invalid user ID');
          }

          const db = getFirestore();
          const userProgressRef = doc(db, 'userProgress', uid);
          const reviewKanjiRef = collection(userProgressRef, 'reviewKanji');
          
          const snapshot = await getDocs(reviewKanjiRef);
          
          // Validate data structure, assigning default totalAccuracy for new kanji
          const criticalKanji = snapshot.docs
            .map(doc => {
              const data = doc.data();
              
              // Check if kanji field exists
              if (!data.kanji) {
                return null;
              }
              
              // If totalAccuracy doesn't exist or isn't a number, set it to 0
              // This handles new kanji that haven't been reviewed yet
              const totalAccuracy = typeof data.totalAccuracy === 'number' 
                ? data.totalAccuracy 
                : 0;
              
              return {
                kanji: data.kanji,
                totalAccuracy: totalAccuracy
              };
            })
            .filter(kanji => 
              kanji !== null && // Remove invalid entries
              kanji.totalAccuracy >= 0 && // Ensure valid range
              kanji.totalAccuracy < 0.5
            );

          // Limit the number of results for performance
          return criticalKanji.slice(0, 100);
        } catch (error) {
          console.error('Critical Kanji fetch error:', error);
          navigate('/error');
          throw error;
        }
      },
      enabled: !!uid,
      staleTime: 5 * 60 * 1000, // Cache for 5 minutes
      retry: 2, // Retry failed requests twice
      refetchOnWindowFocus: false // Prevent unnecessary refetches
    });
};