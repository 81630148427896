import { createCallableFunction } from '../../config/firebase';

// Create a callable instance pointing to the "challengeAnswer" function
const challengeAnswerFn = createCallableFunction('challengeAnswer');

export const checkAnswerWithAI = async (questionData, userInput, correctAnswer) => {
  try {
    const result = await challengeAnswerFn({
      questionData,
      userInput,
      correctAnswer
    });

    // The cloud function returns something like:
    // { isAccepted: "Yes" | "No", explanation: "some string" }
    const { isAccepted, explanation, actualAnswer } = result.data;

    // Convert "Yes" / "No" into a boolean for consistency
    const isAcceptedBool = isAccepted === 'Yes';
    return {
      isAccepted: isAcceptedBool,
      explanation,
      ...(isAcceptedBool && { actualAnswer })
    };

  } catch (error) {
    console.error('Error calling challengeAnswer:', error);
    return {
      isAccepted: false,
      explanation: 'Error calling AI service.'
    };
  }
};