import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/navBar';
import { useUser } from '../components/utils/UserContext';
import { useCatalogFetch } from './hooks/fetchCatalog';
import { useUserContentProgress } from './hooks/fetchUserContentProgress';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from '../components/footer';
import { StarRatingDisplay } from './displays/starRatingDisplay';
import { ClipLoader } from 'react-spinners';
import NotSubscribedToReaderDisplay from './displays/notSubscribedToReaderDisplay';
import { BiXCircle, BiListUl } from 'react-icons/bi';

//utils
import { createCallableFunction } from '../config/firebase';

const genreColorMapInline = {
    'Mystery': { 
        backgroundColor: '#FFE0B2', 
        color: '#E65100',
        japanese: 'ミステリー'
    },
    'Slice-of-life': { 
        backgroundColor: '#E8F5E9', 
        color: '#2E7D32',
        japanese: '日常'
    },
    'Romance': { 
        backgroundColor: '#FFE4E6', 
        color: '#E11D48',
        japanese: 'ロマンス'
    },
    'Sci-fi': { 
        backgroundColor: '#E0F7FA', 
        color: '#006064',
        japanese: 'SF'
    },
    'Drama': { 
        backgroundColor: '#E8EAF6', 
        color: '#283593',
        japanese: 'ドラマ'
    },
    'Fantasy': { 
        backgroundColor: '#FFF3E0', 
        color: '#8B5E3C',
        japanese: 'ファンタジー'
    },
    'Isekai': { 
        backgroundColor: '#FFF9C4', 
        color: '#F9A825',
        japanese: '異世界'
    },
    'Horror': { 
        backgroundColor: '#EFEBE9', 
        color: '#3E2723',
        japanese: 'ホラー'
    },
    'Comedy': { 
        backgroundColor: '#E0F2F1', 
        color: '#00796B',
        japanese: 'コメディ'
    },
    'Podcast': { 
        backgroundColor: '#F3E5F5', 
        color: '#6A1B9A',
        japanese: 'ポッドキャスト'
    },
    'Education': {
        backgroundColor: '#E1F5FE',
        color: '#0277BD',
        japanese: '教育'
    },
    'Cultural': {
        backgroundColor: '#F8BBD0',
        color: '#880E4F',
        japanese: '文化'
    }
};

function MangaAndLightNovelsDisplay() {
    const navigate = useNavigate();
    const { user, loading: userLoading, subscriptionStatus } = useUser();
    const { data: catalogData, isLoading: catalogLoading } = useCatalogFetch();
    const { data: userProgress, isLoading: userProgressLoading, refetch: refetchUserProgress } = useUserContentProgress();
    const [showSubscriptionDialog, setShowSubscriptionDialog] = useState(false);
    const [hoveredRating, setHoveredRating] = useState({});
    const [selectedRating, setSelectedRating] = useState({});
    const [loadingItems, setLoadingItems] = useState({});
    const [selectedGenres, setSelectedGenres] = useState([]);
    const [isLibraryCompactView, setIsLibraryCompactView] = useState(false);
    const [isCatalogCompactView, setIsCatalogCompactView] = useState(false);
    const [expandedDescriptions, setExpandedDescriptions] = useState({});

    useEffect(() => {
        document.title = "OniKanji - Manga and Light Novels";
        if (!userLoading && !user) {
            navigate('/login');
        }
        if (!userLoading && subscriptionStatus !== null && subscriptionStatus !== 'active') {
            setShowSubscriptionDialog(true);
        }
    }, [user, userLoading, navigate, subscriptionStatus]);

    // Wait for user and data to load
    if (userLoading || catalogLoading || userProgressLoading) {
        return (
            <div className="flex justify-center items-center h-screen bg-white">
                <ClipLoader color={"#014156"} size={50} />
            </div>
        );
    }

    const startReading = createCallableFunction('startReading');
    const removeFromLibrary = createCallableFunction('removeFromLibrary');

    const handleReadNow = async (docId, title) => {
        if (subscriptionStatus !== 'active') {
            navigate('/pricing');
            return;
        }
        setLoadingItems(prev => ({ ...prev, [docId]: true }));
        try {
            const result = await startReading({ title, position: 1, chapter: 1 });
            const contentItem = catalogData.find(item => item.id === docId);
            navigate(`/reader/${docId}/1`, {
                state: {
                    imageURL: contentItem?.imageURL,
                    nihongoTitle: contentItem?.nihongoTitle,
                    longTitle: contentItem?.longTitle,
                    type: contentItem?.content?.type || 'text'
                }
            });
        } catch (error) {
            console.error("Error starting reading session:", error);
            toast.error('Something went wrong. Please try again or contact us for support.');
        } finally {
            setLoadingItems(prev => ({ ...prev, [docId]: false }));
        }
    };

    const continueReading = (title, position) => {
        if (subscriptionStatus !== 'active') {
            navigate('/pricing');
            return;
        }
        try {
            const contentItem = catalogData.find(item => item.id === title);
            navigate(`/reader/${title}/${position}`, {
                state: {
                    imageURL: contentItem?.imageURL,
                    nihongoTitle: contentItem?.nihongoTitle,
                    longTitle: contentItem?.longTitle,
                    type: contentItem?.content?.type || 'text'
                }
            });
        } catch (error) {
            console.error("Error navigating to reader:", error);
            toast.error('Something went wrong. Please try again or contact us for support.');
        }
    };

    const isActivelyReading = (catalogTitle, userProgress) => {
        if (!userProgress || userProgress.length === 0) return false;

        const progressItem = userProgress.find(item => item.title === catalogTitle);
        return progressItem?.progress?.active === true;
    };

    const handleRemoveFromLibrary = async (title) => {
        try {
            await removeFromLibrary({ title });
            await refetchUserProgress();
            toast.success('Removed from library');
        } catch (error) {
            console.error('Error removing from library:', error);
            toast.error('Something went wrong. Please try again or contact us for support.');
        }
    };
    const rateTitle = createCallableFunction('rateTitle');

    const handleRatingClick = async (title, rating) => {
        setSelectedRating(prev => ({
            ...prev,
            [title]: rating
        }));
        console.log(`Rating ${rating} stars for title: ${title}`);

        try {
            await rateTitle({ title, rating });
            toast.success('Rating submitted successfully.');
        } catch (error) {
            console.error('Error submitting rating:', error);
            toast.error('Something went wrong. Please try again or contact us for support.');
        }
    };

    const handleGenreSelect = (genre) => {
        setSelectedGenres(prev => {
            if (prev.includes(genre)) {
                return prev.filter(g => g !== genre);
            }
            return [...prev, genre];
        });
    };

    const filteredCatalogData = catalogData?.filter(item => {
        if (selectedGenres.length === 0) return true;
        return item.genre?.some(genre => selectedGenres.includes(genre));
    });

    const handleStudyKanji = () => {
        toast.info('Under Development! 🎉', {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };

    const toggleDescription = (itemId) => {
        setExpandedDescriptions(prev => ({
            ...prev,
            [itemId]: !prev[itemId]
        }));
    };

    return (
        <div className="font-noto-sans-jp">
            <NotSubscribedToReaderDisplay
                isOpen={showSubscriptionDialog}
                onClose={() => setShowSubscriptionDialog(false)}
            />
            <ToastContainer />
            <Navbar user={user} subscriptionStatus={subscriptionStatus} />
            <div className="bg-mainBackgroundColor min-h-screen flex flex-col items-center p-4">
                <div className="w-full sm:max-w-screen-xl bg-mainBackgroundColor p-4 sm:p-6 mt-2">
                    <div className="bg-logoColor shadow-blueBoxShadow rounded-md p-4 relative transition-all duration-300 hover:bg-lightBlueBackground group">
                        <div className="w-full h-full bg-logoColor group-hover:bg-lightBlueText transition-colors duration-300 rounded-md"></div>
                        <div className="space-y-2 relative z-10 p-2">
                            <p className="text-xs sm:text-sm font-semibold text-white group-hover:text-lightBlueText transition-colors duration-300">
                                🚧 Under Development! We're working hard to bring you an amazing manga, light novel, and podcast reading experience! Check the discord for updates! 🚧
                            </p>
                        </div>
                    </div>

                    <div className="max-w-screen-xl w-full mx-auto bg-white shadow-blueBoxShadow rounded-lg p-4 sm:p-6 mt-6 sm:mt-10">
                        <div className="flex justify-between items-center mb-4 sm:mb-6">
                            <h2 className="text-2xl sm:text-3xl font-bold text-black">Your Library</h2>
                            <button
                                onClick={() => setIsLibraryCompactView(!isLibraryCompactView)}
                                className={`p-2 rounded-full hover:bg-gray-100 transition-colors ${
                                    isLibraryCompactView ? 'text-logoColor bg-gray-100' : 'text-gray-600'
                                }`}
                                title={isLibraryCompactView ? "Switch to detailed view" : "Switch to compact view"}
                            >
                                <BiListUl size={24} />
                            </button>
                        </div>

                        {userProgressLoading ? (
                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                                <div className="absolute inset-0 flex items-center justify-center">
                                    <ClipLoader color="#1a73e8" />
                                </div>
                            </div>
                        ) : userProgress && userProgress.some(item => item.progress?.active === true) ? (
                            <div className={`grid ${
                                isLibraryCompactView 
                                    ? 'grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6' 
                                    : 'grid-cols-1 sm:grid-cols-2 lg:grid-cols-4'
                            } gap-4`}>
                                {userProgress
                                    .filter(item => item.progress?.active === true)
                                    .map(({ title, content, progress }) => (
                                        isLibraryCompactView ? (
                                            <div key={title} className="bg-gray-50 rounded-lg p-2 shadow hover:shadow-md transition-shadow flex flex-col relative">
                                                <div>
                                                    <button
                                                        onClick={() => handleRemoveFromLibrary(title)}
                                                        className="absolute top-0 right-0 text-gray-400 hover:text-red-500 transition-colors duration-300 z-10"
                                                        title="Remove from library"
                                                    >
                                                        <BiXCircle size={20} />
                                                    </button>
                                                </div>
                                                <div className="mb-2 text-left flex flex-col items-center relative">
                                                    <div className="relative w-full aspect-square">
                                                        <img
                                                            src={content?.imageURL || '/default-cover.png'}
                                                            alt={`${content?.nihongoTitle || title} Cover`}
                                                            className="w-full h-full object-cover rounded-md shadow-lg z-0"
                                                        />
                                                        {content?.series > 1 && (
                                                            <div className="absolute inset-0 bg-black bg-opacity-50 hover:bg-opacity-0 transition-opacity duration-300 rounded-md flex items-center justify-center z-10">
                                                                <span className="text-white text-sm font-bold transition-opacity duration-300">
                                                                    Ch. {content.series}
                                                                </span>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="mt-auto flex flex-col gap-2 px-1 pb-1">
                                                    <button
                                                        onClick={() => continueReading(title, progress.position)}
                                                        className="w-full bg-logoColor hover:bg-logoColorHover text-white font-bold py-1 px-2 rounded text-xs transition-colors duration-300"
                                                    >
                                                        Continue Reading
                                                    </button>
                                                    <button
                                                        onClick={handleStudyKanji}
                                                        className="w-full bg-darkOrangeColor hover:bg-darkOrangeColorButtonHover text-white font-bold py-1 px-2 rounded text-xs transition-colors duration-300"
                                                    >
                                                        Study Kanji
                                                    </button>
                                                </div>
                                            </div>
                                        ) : (
                                            <div key={title} className="bg-gray-50 rounded-lg p-4 shadow hover:shadow-md transition-shadow flex flex-col w-72 relative">
                                                <div>
                                                    <button
                                                        onClick={() => handleRemoveFromLibrary(title)}
                                                        className="absolute top-0 right-0 text-gray-400 hover:text-red-500 transition-colors duration-300 z-10"
                                                        title="Remove from library"
                                                    >
                                                        <BiXCircle size={24} />
                                                    </button>
                                                </div>
                                                <div className="mb-4 text-left flex flex-col items-center relative">
                                                    <div className="relative">
                                                        <img
                                                            src={content?.imageURL || '/default-cover.png'}
                                                            alt={`${content?.nihongoTitle || title} Cover`}
                                                            className="w-64 h-64 object-cover rounded-md shadow-lg z-0"
                                                        />
                                                        {content?.series > 1 && (
                                                            <div className="absolute inset-0 bg-black bg-opacity-50 hover:bg-opacity-0 transition-opacity duration-300 rounded-md flex items-center justify-center z-10">
                                                                <span className="text-white text-2xl font-bold transition-opacity duration-300">
                                                                    Chapter {content.series}
                                                                </span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <h3 className="text-lg font-semibold text-gray-800">
                                                        {content?.nihongoTitle || title}
                                                    </h3>

                                                    <StarRatingDisplay
                                                        title={title}
                                                        rating={content?.hearts}
                                                        hoveredRating={hoveredRating}
                                                        selectedRating={selectedRating}
                                                        onRatingClick={handleRatingClick}
                                                        setHoveredRating={setHoveredRating}
                                                        readOnly={false}
                                                        existingRating={content?.averageRating}
                                                    />

                                                </div>

                                                <div className="mt-auto">
                                                    <div className="space-y-2 mb-4">
                                                        <p className="text-sm text-gray-600">
                                                            <span className="font-medium">Current Position:</span> Page {progress.position}
                                                        </p>
                                                        <p className="text-sm text-gray-600">
                                                            <span className="font-medium">Difficulty:</span> {content?.estimatedJLPT}
                                                        </p>
                                                        <div className="flex flex-wrap gap-2 mt-3">
                                                            {content?.genre?.map((tag, index) => (
                                                                <span
                                                                    key={index}
                                                                    style={genreColorMapInline[tag] || { backgroundColor: '#F3F3F3', color: '#474747' }}
                                                                    className="px-2 py-1 text-xs rounded-full"
                                                                >
                                                                    {tag}
                                                                </span>
                                                            ))}
                                                        </div>
                                                    </div>

                                                    <div className="flex flex-col gap-2">
                                                        <div className="w-full bg-gray-200 rounded h-8 relative">
                                                            <div
                                                                className="bg-coral h-full rounded transition-all duration-300"
                                                                style={{ width: `${(progress.levelKanjiPercentage * 100)}%` }}
                                                            >
                                                            </div>
                                                            <div className="absolute inset-0 flex items-center justify-center text-xs text-coralText font-medium">
                                                                Known Kanji: {typeof progress.levelKanjiPercentage === 'number' ? `${(progress.levelKanjiPercentage * 100).toFixed(1)}%` : 'Not Graded Yet'}
                                                            </div>
                                                        </div>
                                                        <button
                                                            onClick={() => continueReading(title, progress.position)}
                                                            className="w-full bg-logoColor hover:bg-logoColorHover text-white font-bold py-2 px-4 rounded text-sm transition-colors duration-300"
                                                        >
                                                            Continue Reading
                                                        </button>
                                                        <button
                                                            onClick={handleStudyKanji}
                                                            className="w-full bg-darkOrangeColor hover:bg-darkOrangeColorButtonHover text-white font-bold py-2 px-4 rounded text-sm transition-colors duration-300"
                                                        >
                                                            Study Kanji
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    ))}
                            </div>
                        ) : (
                            <div className="text-center py-8 text-gray-600">
                                <p>No items in your library yet.</p>
                            </div>
                        )}
                    </div>

                    <div className="max-w-screen-xl w-full mx-auto bg-white shadow-blueBoxShadow rounded-lg p-4 sm:p-6 mt-6 sm:mt-10">
                        <div className="flex justify-between items-center mb-4 sm:mb-6">
                            <h2 className="text-2xl sm:text-3xl font-bold text-black text-center sm:text-left">
                                Original Manga, Light Novels, and Podcasts
                            </h2>
                            <button
                                onClick={() => setIsCatalogCompactView(!isCatalogCompactView)}
                                className={`p-2 rounded-full hover:bg-gray-100 transition-colors ${
                                    isCatalogCompactView ? 'text-logoColor bg-gray-100' : 'text-gray-600'
                                }`}
                                title={isCatalogCompactView ? "Switch to detailed view" : "Switch to compact view"}
                            >
                                <BiListUl size={24} />
                            </button>
                        </div>

                        <div className="mb-6">
                            <div className="flex flex-wrap gap-2">
                                {Object.entries(genreColorMapInline).map(([genre, style]) => (
                                    <button
                                        key={genre}
                                        onClick={() => handleGenreSelect(genre)}
                                        className={`px-4 py-2 rounded-full text-sm font-medium transition-all duration-300 ${
                                            selectedGenres.includes(genre)
                                                ? 'ring-2 ring-offset-2 ring-logoColor'
                                                : ''
                                        }`}
                                        style={{
                                            backgroundColor: style.backgroundColor,
                                            color: style.color,
                                            opacity: selectedGenres.length === 0 || selectedGenres.includes(genre) ? 1 : 0.5
                                        }}
                                    >
                                        <div className="flex flex-col items-center">
                                            <span>{genre}</span>
                                            <span className="text-xs opacity-75">#{style.japanese}</span>
                                        </div>
                                    </button>
                                ))}
                            </div>
                        </div>

                        <div className={`grid ${
                            isCatalogCompactView 
                                ? 'grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6' 
                                : 'grid-cols-1 sm:grid-cols-2 lg:grid-cols-3'
                        } gap-6`}>
                            {catalogLoading ? (
                                <>
                                    <div className="absolute inset-0 flex items-center justify-center">
                                        <ClipLoader color="#1a73e8" />
                                    </div>
                                </>
                            ) : filteredCatalogData && filteredCatalogData.length > 0 ? (
                                filteredCatalogData.map((item) => {
                                    const progressData = userProgress?.find(prog => prog.title === item.id);
                                    const levelKanjiPercentage = progressData?.progress?.levelKanjiPercentage || 0;

                                    return isCatalogCompactView ? (
                                        <div key={item.id} className="bg-gray-50 rounded-lg p-2 shadow hover:shadow-md transition-shadow flex flex-col relative">
                                            <div className="mb-2 text-left flex flex-col items-center relative">
                                                <div className="relative w-full aspect-square">
                                                    <img
                                                        src={item.imageURL}
                                                        alt={`${item.nihongoTitle} Cover`}
                                                        className="w-full h-full object-cover rounded-md shadow-lg z-0"
                                                    />
                                                    {item.series > 1 && (
                                                        <div className="absolute inset-0 bg-black bg-opacity-50 hover:bg-opacity-0 transition-opacity duration-300 rounded-md flex items-center justify-center z-10">
                                                            <span className="text-white text-sm font-bold transition-opacity duration-300">
                                                                Ch. {item.series}
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="mt-auto flex flex-col gap-2 px-1 pb-1">
                                                <button
                                                    className="w-full bg-darkOrangeColor hover:bg-darkOrangeColorButtonHover text-white font-bold py-1 px-2 rounded text-xs transition-colors duration-300"
                                                    onClick={() => handleStudyKanji()}
                                                >
                                                    Study Kanji
                                                </button>
                                                {isActivelyReading(item.title, userProgress) ? (
                                                    <button
                                                        className="w-full bg-logoColor hover:bg-logoColorHover text-white font-bold py-1 px-2 rounded text-xs transition-colors duration-300"
                                                        onClick={() => {
                                                            const progressItem = userProgress.find(prog => prog.title === item.title);
                                                            continueReading(item.id, progressItem.progress.position);
                                                        }}
                                                    >
                                                        Continue Reading
                                                    </button>
                                                ) : (
                                                    <button
                                                        className="w-full bg-logoColor hover:bg-logoColorHover text-white font-bold py-1 px-2 rounded text-xs transition-colors duration-300 flex items-center justify-center"
                                                        onClick={() => handleReadNow(item.id, item.title)}
                                                        disabled={loadingItems[item.id]}
                                                    >
                                                        {loadingItems[item.id] ? (
                                                            <ClipLoader color="#ffffff" size={20} />
                                                        ) : (
                                                            'Read Now'
                                                        )}
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    ) : (
                                        <div key={item.id} className="flex flex-col bg-gray-100 p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 h-full">
                                            <div className="flex-grow">
                                                <div className="flex-shrink-0 mb-4 relative">
                                                    <img
                                                        src={item.imageURL}
                                                        alt={`${item.nihongoTitle} Cover`}
                                                        className="w-full object-cover rounded-md shadow-lg z-0"
                                                    />
                                                    {item.series > 1 && (
                                                        <div className="absolute inset-0 bg-black bg-opacity-50 hover:bg-opacity-0 transition-opacity duration-300 rounded-md flex items-center justify-center z-10">
                                                            <span className="text-white text-2xl font-bold transition-opacity duration-300">
                                                                Chapter {item.series}
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="mb-4">
                                                    <div className="flex justify-between items-start mb-2">
                                                        <h3 className="text-xl font-bold text-gray-800">{item.nihongoTitle}</h3>
                                                    </div>
                                                    <p className="text-sm text-gray-600 mb-1"><strong>Author:</strong> {item.author}</p>
                                                    <p className="text-sm text-gray-600 mb-1">
                                                        <strong>Difficulty:</strong> <span className="text-blue-600">{item.difficulty}</span>
                                                    </p>
                                                    <div className="relative">
                                                        <p className={`text-sm text-gray-600 mt-2 mb-2 ${expandedDescriptions[item.id] ? '' : 'line-clamp-5'}`}>
                                                            {item.description}
                                                        </p>
                                                        {item.description && item.description.length > 200 && (
                                                            <button
                                                                onClick={() => toggleDescription(item.id)}
                                                                className="text-logoColor hover:text-logoColorHover text-sm font-medium"
                                                            >
                                                                {expandedDescriptions[item.id] ? 'Show Less' : 'Show More'}
                                                            </button>
                                                        )}
                                                    </div>
                                                    {item.authorsNote && (
                                                        <div className="relative">
                                                            <div 
                                                                className={`text-sm text-gray-500 italic mt-1 mb-2 ${expandedDescriptions[`${item.id}-note`] ? '' : 'line-clamp-5'}`}
                                                            >
                                                                <strong>Author's Note:</strong>{' '}
                                                                <div 
                                                                    dangerouslySetInnerHTML={{ 
                                                                        __html: item.authorsNote.replace(
                                                                            /<a\s+(?:[^>]*?\s+)?href="([^"]*)"[^>]*>(.*?)<\/a>/g, 
                                                                            '<button class="text-logoColor hover:text-logoColorHover text-sm font-medium" onclick="window.open(\'$1\', \'_blank\')">Click to View</button>'
                                                                        )
                                                                    }}
                                                                    className="inline"
                                                                />
                                                            </div>
                                                            {item.authorsNote.length > 200 && (
                                                                <button
                                                                    onClick={() => toggleDescription(`${item.id}-note`)}
                                                                    className="text-logoColor hover:text-logoColorHover text-sm font-medium"
                                                                >
                                                                    {expandedDescriptions[`${item.id}-note`] ? 'Show Less' : 'Show More'}
                                                                </button>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>

                                            </div>

                                            <div className="mt-auto">
                                                <div className="flex flex-col gap-2 mb-2">
                                                    <div className="bg-white p-2 rounded-lg shadow flex items-center justify-between">
                                                        <span className="text-sm font-medium text-gray-600">Rating:</span>
                                                        <StarRatingDisplay
                                                            title={item.title}
                                                            rating={item.averageRating}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                    <div className="bg-white p-2 rounded-lg shadow flex items-center justify-between">
                                                        <span className="text-sm font-medium text-gray-600">Unique Kanji:</span>
                                                        <span className="text-sm font-bold text-logoColor">{item.uniqueKanjiCount}</span>
                                                    </div>
                                                    <div className="bg-white p-2 rounded-lg shadow flex items-center justify-between">
                                                        <span className="text-sm font-medium text-gray-600">Character Count:</span>
                                                        <span className="text-sm font-bold text-logoColor">{item.characterCount}</span>
                                                    </div>
                                                    <div className="bg-white p-2 rounded-lg shadow flex items-center justify-between">
                                                        <span className="text-sm font-medium text-gray-600">Estimated JLPT:</span>
                                                        <span className="text-sm font-bold text-logoColor">{item.estimatedJLPT}</span>
                                                    </div>
                                                </div>

                                                <div className="w-full bg-gray-200 rounded h-8 relative mb-4">
                                                    <div
                                                        className="bg-coral h-full rounded transition-all duration-300"
                                                        style={{ width: `${(item.levelKanjiPercentage * 100)}%` }}
                                                    >
                                                    </div>
                                                    <div className="absolute inset-0 flex items-center justify-center text-xs text-coralText font-medium">
                                                        Known Kanji: {typeof item.levelKanjiPercentage === 'number' ? `${(item.levelKanjiPercentage * 100).toFixed(1)}%` : 'Not Graded Yet'}
                                                    </div>
                                                </div>

                                                <div className="flex flex-wrap gap-2 mb-4">
                                                    {Array.isArray(item.genre) && item.genre.map((tag, index) => {
                                                        const styles = {
                                                            ...genreColorMapInline[tag] || { backgroundColor: '#F3F3F3', color: '#474747' },
                                                            padding: '0.25rem 0.5rem',
                                                            borderRadius: '9999px',
                                                            fontSize: '0.75rem',
                                                            fontWeight: '500',
                                                            transition: 'all 0.2s',
                                                            display: 'inline-block'
                                                        };

                                                        return (
                                                            <span
                                                                key={`${tag}-${index}`}
                                                                style={styles}
                                                            >
                                                                {tag}
                                                            </span>
                                                        );
                                                    })}
                                                </div>

                                                <div className="flex gap-4">
                                                    <button
                                                        className="flex-1 bg-darkOrangeColor hover:bg-darkOrangeColorButtonHover text-white font-bold py-2 px-4 rounded transition-colors duration-300"
                                                        onClick={() => handleStudyKanji()}
                                                    >
                                                        Study Kanji
                                                    </button>
                                                    {isActivelyReading(item.title, userProgress) ? (
                                                        <button
                                                            className="flex-1 bg-logoColor hover:bg-logoColorHover text-white font-bold py-2 px-4 rounded transition-colors duration-300"
                                                            onClick={() => {
                                                                const progressItem = userProgress.find(prog => prog.title === item.title);
                                                                continueReading(item.id, progressItem.progress.position);
                                                            }}
                                                        >
                                                            Resume Reading
                                                        </button>
                                                    ) : (
                                                        <button
                                                            className="flex-1 bg-logoColor hover:bg-logoColorHover text-white font-bold py-2 px-4 rounded transition-colors duration-300 flex items-center justify-center"
                                                            onClick={() => handleReadNow(item.id, item.title)}
                                                            disabled={loadingItems[item.id]}
                                                        >
                                                            {loadingItems[item.id] ? (
                                                                <ClipLoader color="#ffffff" size={20} />
                                                            ) : (
                                                                'Read Now'
                                                            )}
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <div className="text-center py-8 text-gray-600">
                                    <p>No content available at the moment.</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default MangaAndLightNovelsDisplay;
