// StatsDisplay.js
import React from 'react';
import correct from '../images/correct.png';
import wrong from '../images/wrong.png';
import accuracy from '../images/accuracy.png';
import skip from '../images/skip.png';
import sound_on from '../images/sound_on.png';
import sound_off from '../images/sound_off.png';
// import { BiShow, BiLowVision } from "react-icons/bi";  // Commented out

const StatsDisplay = ({
  isSoundOn,
  toggleSound,
  stats,
  highlightedStat,
  // showFurigana,  // Commented out
  // toggleFurigana,  // Commented out
}) => {
  return (
    <div className="absolute top-0 right-0 flex items-center space-x-2 p-2 bg-white rounded-lg shadow-md">
      <img
        src={isSoundOn ? sound_on : sound_off}
        alt={isSoundOn ? 'Sound On' : 'Sound Off'}
        className="w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6 cursor-pointer"
        title={isSoundOn ? 'Toggle Sound Off' : 'Toggle Sound On'}
        onClick={toggleSound}
      />
      {/* Furigana toggle button - commented out for now
      <div 
        className="relative cursor-pointer text-[#009bce] hover:text-[#007a9e] w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6 flex items-center justify-center"
        onClick={toggleFurigana}
        title={showFurigana ? "Hide Furigana" : "Show Furigana"}
      >
        <span className="text-lg">あ</span>
        <span className="absolute -top-1 -right-1 text-xs">
          {showFurigana ? 
            <BiShow className="text-sm" /> : 
            <BiLowVision className="text-sm" />
          }
        </span>
      </div>
      */}
      <span></span>
      <img
        src={correct}
        alt="Correct"
        className="w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6"
        title="Number of Correct Responses"
      />
      <span
        className={`text-sm sm:text-base md:text-lg font-bold transition-colors duration-300 ${highlightedStat === 'correct' ? 'text-green-500' : ''
          }`}
        title="Number of Correct Responses"
      >
        {stats.correct}
      </span>
      <img
        src={wrong}
        alt="Wrong"
        className="w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6"
        title="Number of Incorrect Responses"
      />
      <span
        className={`text-sm sm:text-base md:text-lg font-bold transition-colors duration-300 ${highlightedStat === 'wrong' ? 'text-red-500' : ''
          }`}
        title="Number of Incorrect Responses"
      >
        {stats.wrong}
      </span>
      <img
        src={accuracy}
        alt="Accuracy"
        className="w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6"
        title="Total Session Accuracy"
      />
      <span
        className="text-sm sm:text-base md:text-lg font-bold text-black"
        title="Total Session Accuracy"
      >
        {stats.accuracy}%
      </span>
      <img
        src={skip}
        alt="Skip"
        className="w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6"
        title="Number of Questions Skipped"
      />
      <span
        className={`text-sm sm:text-base md:text-lg font-bold transition-colors duration-300 ${highlightedStat === 'skip' ? 'text-yellow-500' : ''
          }`}
        title="Number of Questions Skipped"
      >
        {stats.skip}
      </span>
    </div>
  );
};

export default StatsDisplay;
