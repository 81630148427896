// Tooltip.js
import React, { useState, useRef, useEffect } from 'react';
import { BiHelpCircle, BiXCircle } from "react-icons/bi";

const Tooltip = ({ toggleKeyboard }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setShowTooltip(false);
      }
    };

    if (showTooltip) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showTooltip]);

  return (
    <div>
      <BiHelpCircle
        className="absolute top-2 left-2 text-2xl text-darkBlueColor cursor-pointer transition-transform hover:scale-110"
        onClick={toggleTooltip}
      />
      {showTooltip && (
        <div
          ref={tooltipRef}
          className="absolute top-10 left-2 bg-red-100 border-2 border-red-500 rounded-lg p-4 shadow-lg z-50 max-w-xs"
        >
          <BiXCircle
            className="absolute top-2 right-2 text-xl text-red-500 cursor-pointer"
            onClick={toggleTooltip}
          />
          <h3 className="text-red-700 font-bold mb-2">Review Tips</h3>
          <ul className="text-red-600 text-sm list-disc list-inside">
            <li>
              Press Enter to check your answer. Enter again to continue to the next question.
            </li>
            <li>
              We measure speed, accuracy, skips, and kanji question attempts to train your algo.
            </li>
            <li>Use the "Show Context Hint" for a helpful clue.</li>
            <li>
              "Show Answer" reveals the correct response, but the question will be marked as incorrect.
            </li>
            <li>Skip difficult questions to revisit later.</li>
            <li>Toggle sound for audio feedback.</li>
            <li 
              onClick={() => {
                toggleTooltip();
                toggleKeyboard();
              }}
              className="cursor-pointer hover:text-red-800"
            >
              <u>Click here to see hiragana to English keyboard reference</u>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Tooltip;
