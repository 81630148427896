import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useContentFetch } from './hooks/fetchContent';
import { useUser } from '../utils/UserContext';
import { formatFuriganaText } from './utils/readerFurigana';
import ReaderNavigation from './utils/readerNavigation';
import { useAudioPlayback } from '../utils/generateAudio';
import { useSentenceDefinition } from './hooks/fetchAISentence';
import Popup from './utils/readerPopUpInfoBox';
import KanjiPopup from './utils/readerKanjiPopUpInfoBox';
import { useKanjiData } from './hooks/fetchImmersionContentKanji';
import { createCallableFunction } from '../../config/firebase';
import { useQueryClient } from '@tanstack/react-query';
import PodcastPlayer from './utils/podcastPlayer';

//utils
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ReaderDisplay() {
  const navigate = useNavigate();
  const { user, loading: userLoading, subscriptionStatus } = useUser();
  const [fontSizeIndex, setFontSizeIndex] = useState(() => {
    // Check if user is on mobile and set smallest font size (index 0)
    return window.innerWidth < 768 ? 0 : 2;
  });
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isKanjiPopupOpen, setIsKanjiPopupOpen] = useState(false);
  const [targetRect, setTargetRect] = useState(null);
  const [selectedKanji, setSelectedKanji] = useState([]);
  const [kanjiDataList, setKanjiDataList] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [selectedSentence, setSelectedSentence] = useState(null);
  const [showHelp, setShowHelp] = useState(false);
  const [showFurigana, setShowFurigana] = useState(true);

  const fontSizes = ['1.25rem', '1.45rem', '1.65rem', '1.85rem', '2.05rem'];

  const { handleTextToSpeech, isAudioPlaying, PulsingVolumeIcon } = useAudioPlayback();
  const { 
    data: definition, 
    isLoading: loading, 
    error: aiError, 
    refetch,
    fetchSentenceDefinition 
  } = useSentenceDefinition(selectedSentence);
  const { data: kanjiData, isLoading: kanjiLoading, error: kanjiError } = useKanjiData(selectedKanji);

  // Track if content has been fetched to avoid reprocessing.
  const hasFetchedData = useRef(false);

  const { docId, position } = useParams();
  const numericPosition = Number(position);
  const [currentPage, setCurrentPage] = useState(numericPosition - 1);
  const { data: currentChapter, isLoading, error } = useContentFetch(docId);
  
  const saveReadingPosition = createCallableFunction('saveReadingPosition');

  const queryClient = useQueryClient();

  const location = useLocation();
  // Get type and image/title info from location state if provided.
  const { type: stateType, imageURL, nihongoTitle, longTitle } = location.state || {};
  
  // Use the type from the fetched content (currentChapter) or fallback to the state type.
  const contentType = currentChapter?.type || stateType || 'lightNovel';

  const handleNavigateHome = async () => {
    setIsSaving(true);
    const savePromise = saveReadingPosition({ title: currentChapter?.title, position: currentPage + 1 });
    const delayPromise = new Promise(resolve => setTimeout(resolve, 2000));

    try {
      await Promise.all([savePromise, delayPromise]);
      navigate('/manga-and-light-novels/');
    } catch (error) {
      console.error("Error saving reading position:", error);
      toast.error("Error saving position, please try again or contact us");
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (!userLoading) {
      if (!user) {
        navigate('/login');
      } else if (subscriptionStatus !== 'active') {
        navigate('/pricing');
      }
    }
  }, [user, userLoading, navigate, subscriptionStatus, docId]);

  useEffect(() => {
    if (currentChapter && !hasFetchedData.current) {
      hasFetchedData.current = true;
    }
  }, [currentChapter]);

  useEffect(() => {
    if (kanjiData) {
      setKanjiDataList(kanjiData);
    }
  }, [kanjiData]);

  const handleSentenceClick = async (sentence, arg2, arg3) => {
    // In non-podcast mode, arg2 is the event.
    // In podcast mode, arg2 is the 'line' object and arg3 is the event.
    const event = arg3 || arg2;
    const rect = event.currentTarget.getBoundingClientRect();
    setTargetRect(rect);
    setIsPopupOpen(true);
    setSelectedSentence(sentence);
  };

  const handleKanjiClick = (kanji, e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    setTargetRect(rect);
    setIsKanjiPopupOpen(true);

    const kanjiArray = kanji.length > 1 ? kanji.split('') : [kanji];
    
    if (JSON.stringify(selectedKanji) !== JSON.stringify(kanjiArray)) {
      setSelectedKanji(kanjiArray);
    }
  };

  const paginateByBreaks = (text = '', breaksPerPage = 5) => {
    const pages = [];
    let currentPageContent = [];
    let breakCount = 0;
    const parts = text.split(/(<br>)/);
    parts.forEach(part => {
      if (part === '<br>') {
        breakCount++;
      }
      currentPageContent.push(part);
      if (breakCount === breaksPerPage) {
        pages.push(currentPageContent.join(''));
        currentPageContent = [];
        breakCount = 0;
      }
    });
    if (currentPageContent.length > 0) {
      pages.push(currentPageContent.join(''));
    }
    return pages;
  };

  const paginatePodcastContent = (transcript = [], linesPerPage = 10) => {
    const pages = [];
    for (let i = 0; i < transcript.length; i += linesPerPage) {
      pages.push(transcript.slice(i, i + linesPerPage));
    }
    return pages;
  };

  let allPages = [];
  if (currentChapter) {
    if (currentChapter.type === 'podcast') {
      allPages = paginatePodcastContent(currentChapter.transcript, 10);
    } else {
      allPages = paginateByBreaks(currentChapter.content, 5);
    }
  }

  let processedContent = null;
  if (currentChapter && currentChapter.type !== 'podcast') {
    const currentContent = allPages[currentPage] || '';
    processedContent = formatFuriganaText(
      currentContent, 
      handleTextToSpeech, 
      isAudioPlaying,
      handleSentenceClick,
      handleKanjiClick,
      showFurigana
    );
  }

  const handleHelpClick = (e) => {
    e.stopPropagation();
    setShowHelp(prev => !prev);
  };

  const toggleFurigana = (e) => {
    e.stopPropagation();
    setShowFurigana(prev => !prev);
  };

  useEffect(() => {
    const handleDocumentClick = () => {
      if (showHelp) {
        setShowHelp(false);
      }
    };
    document.addEventListener('click', handleDocumentClick);
    return () => document.removeEventListener('click', handleDocumentClick);
  }, [showHelp]);

  const handleAutoPageTurn = () => {
    if (currentPage < allPages.length - 1) {
      setCurrentPage(prev => prev + 1);
    }
  };

  // Add this function to handle page navigation with auto-scrolling
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    
    // Auto-scroll to top on mobile/small screens
    if (window.innerWidth < 768) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  if (userLoading || isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  // Render content based on the type.
  const renderContent = () => {
    if (contentType === 'podcast') {
      return (
        <>
          {currentPage === 0 && imageURL && (
            <div className="flex flex-col items-center mb-8">
              <img 
                src={imageURL} 
                alt="Story Cover" 
                className="max-w-md w-full rounded-lg shadow-lg mb-4"
              />
              <div className="mx-auto w-2/3">
                {nihongoTitle && <h2 className="text-center text-2xl mb-2">{nihongoTitle}</h2>}
                {longTitle && <h3 className="text-center text-xl mb-4">{longTitle}</h3>}
              </div>  
            </div>
          )}
          <PodcastPlayer 
            transcript={allPages[currentPage] || []}
            audioUrl={currentChapter.audioUrl}
            handleSentenceClick={handleSentenceClick}
            handleKanjiClick={handleKanjiClick}
            showFurigana={showFurigana}
            fontSize={fontSizes[fontSizeIndex]}
          />
        </>
      );
    } else {
      return (
        <>
          {currentPage === 0 && imageURL && (
            <div className="flex flex-col items-center mb-8">
              <img 
                src={imageURL} 
                alt="Story Cover" 
                className="max-w-md w-full rounded-lg shadow-lg mb-4"
              />
              <div className="mx-auto w-2/3">
                {nihongoTitle && <h2 className="text-center text-2xl mb-2">{nihongoTitle}</h2>}
                {longTitle && <h3 className="text-center text-xl mb-4">{longTitle}</h3>}
              </div>  
            </div>
          )}
          <div className="text-gray-800 font-noto-sans-jp" style={{ fontSize: fontSizes[fontSizeIndex] }}>
            {processedContent}
          </div>
        </>
      );
    }
  };

  return (
    <div className="min-h-screen bg-[#f6f6f6] p-2 sm:p-4 md:p-6 lg:p-8">
      <div className="max-w-5xl mx-auto bg-white min-h-[calc(100vh-2rem)] shadow-sm relative">
        {showHelp && (
          <div className="absolute top-16 right-4 bg-white p-4 rounded-lg shadow-lg border border-gray-200 z-50 max-w-sm">
            <h3 className="font-bold mb-2">Reading Tips:</h3>
            <ul className="space-y-2 text-sm">
              <li>• Home icon will save your current position</li>
              <li>• Click any kanji character to see its meaning</li>
              <li>• Click any sentence to see its translation</li>
              <li>• Use the speaker icon to hear pronunciation</li>
              <li>• Adjust text size using the Aa button</li>
              <li>• Toggle furigana on and off</li>
              <li>• Press Spacebar to play/pause audio</li>
            </ul>
          </div>
        )}
        
        <header className="bg-white/90 border-b border-gray-200">
          <ReaderNavigation
            currentPage={currentPage}
            totalPages={allPages.length}
            onFirstPage={() => handlePageChange(0)}
            onPreviousPage={() => handlePageChange(Math.max(currentPage - 1, 0))}
            onNextPage={() => handlePageChange(Math.min(currentPage + 1, allPages.length - 1))}
            onLastPage={() => handlePageChange(allPages.length - 1)}
            onCycleFontSize={() => setFontSizeIndex((prevIndex) => (prevIndex + 1) % fontSizes.length)}
            onNavigateHome={handleNavigateHome}
            isSaving={isSaving}
            onHelpClick={handleHelpClick}
            onToggleFurigana={toggleFurigana}
            showFurigana={showFurigana}
          />
        </header>

        <main className="p-4 sm:p-8 md:p-10 lg:p-12">
          <div className="leading-[2.75rem] sm:leading-[2.75rem] max-w-none">
            {renderContent()}
          </div>
        </main>

        <footer className="bg-white/90 border-t border-gray-200">
          <ReaderNavigation
            currentPage={currentPage}
            totalPages={allPages.length}
            onFirstPage={() => handlePageChange(0)}
            onPreviousPage={() => handlePageChange(Math.max(currentPage - 1, 0))}
            onNextPage={() => handlePageChange(Math.min(currentPage + 1, allPages.length - 1))}
            onLastPage={() => handlePageChange(allPages.length - 1)}
            onCycleFontSize={() => setFontSizeIndex((prevIndex) => (prevIndex + 1) % fontSizes.length)}
            onNavigateHome={handleNavigateHome}
            onHelpClick={handleHelpClick}
            onToggleFurigana={toggleFurigana}
            showFurigana={showFurigana}
          />
        </footer>
      </div>

      <Popup
        isOpen={isPopupOpen}
        boundingRect={targetRect}
        onClose={() => {
          setIsPopupOpen(false);
          setSelectedSentence(null);
        }}
        content={loading ? 'Loading...' : aiError ? `Error: ${aiError}` : definition}
      />
      <KanjiPopup
        isOpen={isKanjiPopupOpen}
        boundingRect={targetRect}
        onClose={() => {
          setIsKanjiPopupOpen(false);
        }}
        content={kanjiLoading ? null : kanjiError ? `Error: ${kanjiError}` : kanjiDataList}
      />
    </div>
  );
}

export default ReaderDisplay;
