import React, { useState, useEffect } from 'react';
import { useUser } from '../components/utils/UserContext';
import { getFirestore, collection, query, where, getDocs, addDoc, onSnapshot } from 'firebase/firestore';
import Footer from '../components/footer';
import Navbar from '../components/navBar';
import { ClipLoader } from 'react-spinners'; 
import { useNavigate } from 'react-router-dom';

import { getFunctions, httpsCallable } from 'firebase/functions';
import { getApp } from 'firebase/app';


//pricing images
const pricingFishImage = "https://firebasestorage.googleapis.com/v0/b/kf-demo-project-b0508.appspot.com/o/assets%2FpricingAssets%2Fonikanji-monthly-sub.png?alt=media&token=c68c41b4-7e2e-405f-8bf1-0953defed5db";
const pricingBonsaiImage = "https://firebasestorage.googleapis.com/v0/b/kf-demo-project-b0508.appspot.com/o/assets%2FpricingAssets%2Fonikanji-yearly-sub.png?alt=media&token=4f9ed37f-5f69-4698-bf14-68e130d37f16";
const pricingOniMaskImage = "https://firebasestorage.googleapis.com/v0/b/kf-demo-project-b0508.appspot.com/o/assets%2FpricingAssets%2Fonikanji-lifetime-sub.png?alt=media&token=4e42ad49-fa03-4a0d-b236-0d8da83ff656";

function OnikanjiPricing() {
    const db = getFirestore();
    const { user, loading: userLoading, subscriptionStatus, subscriptionName } = useUser();
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isManageSubscriptionLoading, setIsManageSubscriptionLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "OniKanji - Pricing";
        const fetchProducts = async () => {
            try {
                const productsRef = collection(db, 'subscriptionPlans');
                const q = query(productsRef, where('active', '==', true));
                const querySnapshot = await getDocs(q);

                const fetchedProducts = [];
                for (const doc of querySnapshot.docs) {
                    const productData = doc.data();
                    const pricesRef = collection(doc.ref, 'prices');
                    const priceQuery = query(pricesRef, where('active', '==', true));
                    const priceSnap = await getDocs(priceQuery);
                    const prices = [];
                    priceSnap.docs.forEach((priceDoc) => {
                        prices.push({
                            id: priceDoc.id,
                            ...priceDoc.data(),
                        });
                    });
                    fetchedProducts.push({ id: doc.id, ...productData, prices });
                }
                setProducts(fetchedProducts);
            } catch (error) {
                console.error("Error fetching products:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchProducts();

        return () => {
            setLoading(false);
        };
    }, [db]);

    useEffect(() => {
        console.log('Current subscription name:', subscriptionName);
    }, [subscriptionName]);

    // Add loading screen
    if (loading || userLoading) {
        return (
            <div className="min-h-screen flex justify-center items-center bg-mainBackground">
                <ClipLoader size={50} color="#014156" />
            </div>
        );
    }

    const handleSubscribe = async (product) => {
        if (!user) {
            navigate('/signup');
            return;
        }

        setLoading(true);

        try {
            const priceId = product.prices[0].id;
            // Determine if the product is a one-time charge or a subscription
            const mode = product.prices[0].recurring ? 'subscription' : 'payment';

            const docRef = await addDoc(collection(db, 'UserCollection', user.uid, 'checkout_sessions'), {
                price: priceId,
                allow_promotion_codes: true,
                success_url: `${window.location.origin}/home`,
                cancel_url: `${window.location.origin}/pricing`,
                mode: mode,
            });

            onSnapshot(docRef, (snap) => {
                const { error, url } = snap.data();
                if (error) {
                    alert(`An error occurred: ${error.message}`);
                    setLoading(false);
                }
                if (url) {
                    window.location.assign(url);
                }
            });
        } catch (error) {
            console.error("Error creating checkout session:", error);
            alert("An error occurred while creating the checkout session. Please try again.");
            setLoading(false);
        }
    };

    const handleManageSubscription = async () => {
        setIsManageSubscriptionLoading(true);
        try {
            const functions = getFunctions(getApp(), 'us-east1');
            const createPortalLink = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');

            const { data } = await createPortalLink({
                returnUrl: window.location.origin,
                locale: "auto"
            });

            window.location.assign(data.url);
        } catch (error) {
            console.error("Error creating portal link:", error);
            alert("An error occurred while trying to manage your subscription. Please try again.");
        } finally {
            setIsManageSubscriptionLoading(false);
        }
    };

    const getButtonText = (productName) => {
        if (subscriptionStatus === 'active') {
            if (subscriptionName === productName) {
                return 'Manage Subscription';
            } else if (productName === 'OniKanji Lifetime Membership') {
                return 'One Time Purchase';
            } else {
                return 'Upgrade Now';
            }
        }
        return 'Subscribe Now';
    };

    return (
        <div className="font-noto-sans-jp">
            <Navbar user={user} subscriptionStatus={subscriptionStatus} />
            <div className="w-full py-12 md:py-20 px-4 md:px-8 bg-mainBackground">
                <div className="max-w-6xl mx-auto">
                    <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold text-logoColor mb-8 md:mb-12 text-center">
                        {subscriptionStatus === 'active' ? 'Upgrade or Manage Your Subscription' : 'Choose Your Learning Path'}
                    </h2>

                    <h4 className="text-sm md:text-lg lg:text-xl font-semibold text-black mb-4 md:mb-8 text-center">
                        OniKanji is currently in Beta. All prices are reduced to reflect this. We are working on rapid development and improvements. Prices may change as we add more features and value after Beta. However, all current subscribers will be grandfathered in and will not experience any price changes. Thank you for your support!
                    </h4>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 bg-blueBoxShadow rounded-lg p-4 md:p-8 w-full md:w-auto">
                        {products.map((product, index) => (
                            <div key={product.id} className={`bg-white p-8 border-2 border-gray-200 rounded-lg shadow-md flex flex-col h-full transition-all duration-300 hover:shadow-xl hover:scale-105`}>
                                <div className="mb-6">
                                    <div className="aspect-w-16 aspect-h-9 relative">
                                        <img 
                                            src={index === 0 ? pricingFishImage : 
                                                 index === 1 ? pricingBonsaiImage : 
                                                 pricingOniMaskImage} 
                                            alt={`${product.name} Plan`} 
                                            className="w-full h-full object-contain rounded-lg" 
                                        />
                                    </div>
                                </div>
                                <h3 className="text-2xl font-bold text-logoColor mb-4">{product.name}</h3>
                                {index === 0 && (
                                    <>
                                        <p className="text-4xl font-bold mb-2">$9.00<span className="text-lg font-normal">/month</span></p>
                                        <p className="text-lg font-normal mb-2">Billed monthly</p>
                                        <p className="text-sm text-green-600 mb-6">Most flexible option</p>
                                    </>
                                )}
                                {index === 1 && (
                                    <>
                                        <p className="text-4xl font-bold mb-2">$7.00<span className="text-lg font-normal">/month</span></p>
                                        <p className="text-2xl font-bold mb-2">$84.00<span className="text-base font-normal">/year</span></p>
                                        <p className="text-sm text-gray-600 mb-6">Annual commitment required</p>
                                    </>
                                )}
                                {index === 2 && (
                                    <>
                                        <p className="text-4xl font-bold mb-2">
                                            <span className="line-through">$290.00</span>
                                            <span className="ml-2">$190.00</span>
                                        </p>
                                        <p className="text-lg font-normal mb-2">One Time Charge</p>
                                        <p className="text-sm text-gray-600 mb-6">Limited time offer</p>
                                    </>
                                )}
                                <ul className="mb-8 flex-grow space-y-3">
                                    <li className="flex items-center">
                                        <span className="text-green-500 mr-2">✓</span>
                                        {index === 0
                                            ? "Perfect for exploring and learning at your pace"
                                            : index === 1
                                            ? "For those who prefer annual billing"
                                            : "For dedicated lifetime learners"}
                                    </li>
                                    <li className="flex items-center">
                                        <span className="text-green-500 mr-2">✓</span>
                                        Full access to all features
                                    </li>
                                    {index === 0 && (
                                        <>
                                            <li className="flex items-center">
                                                <span className="text-green-500 mr-2">✓</span>
                                                Cancel anytime
                                            </li>
                                            <li className="flex items-center">
                                                <span className="text-green-500 mr-2">✓</span>
                                                Most flexible option
                                            </li>
                                        </>
                                    )}
                                    {index === 1 && (
                                        <>
                                            <li className="flex items-center">
                                                <span className="text-green-500 mr-2">✓</span>
                                                Save $24 annually
                                            </li>
                                            <li className="flex items-center">
                                                <span className="text-green-500 mr-2">✓</span>
                                                Best value for committed learners
                                            </li>
                                        </>
                                    )}
                                    {index === 2 && (
                                        <>
                                            <li className="flex items-center">
                                                <span className="text-green-500 mr-2">✓</span>
                                                Discord Community Role
                                            </li>
                                            <li className="flex items-center">
                                                <span className="text-green-500 mr-2">✓</span>
                                                Exclusive early access to new features
                                            </li>
                                            <li className="flex items-center">
                                                <span className="text-green-500 mr-2">✓</span>
                                                Limited time offer
                                            </li>
                                        </>
                                    )}
                                </ul>
                                {subscriptionStatus === 'active' && subscriptionName === product.name ? (
                                    <button 
                                        onClick={handleManageSubscription}
                                        className="bg-logoColor hover:bg-[#015f80] text-white font-bold text-lg py-3 px-6 rounded-lg transition duration-300 w-full text-center flex items-center justify-center"
                                        disabled={isManageSubscriptionLoading}
                                    >
                                        {isManageSubscriptionLoading ? (
                                            <>
                                                <ClipLoader size={16} color="#014156" className="mr-2" />
                                                Processing...
                                            </>
                                        ) : (
                                            getButtonText(product.name)
                                        )}
                                    </button>
                                ) : (
                                    <>
                                                                            {subscriptionStatus === 'active' && 
                                         product.name === 'OniKanji Lifetime Membership' && 
                                         (subscriptionName === 'OniKanji Monthly Subscription' || 
                                          subscriptionName === 'OniKanji Yearly Subscription') && (
                                            <p className="text-sm text-gray-600 mt-2 mb-2 text-center">
                                                Your current subscription will be automatically cancelled after purchasing lifetime access
                                            </p>
                                        )}
                                        <button 
                                            onClick={() => handleSubscribe(product)}
                                            className="bg-logoColor hover:bg-[#015f80] text-white font-bold text-lg py-3 px-6 rounded-lg transition duration-300 w-full text-center flex items-center justify-center"
                                            disabled={loading}
                                        >
                                            {loading ? (
                                                <ClipLoader color="#014156" size={24} />
                                            ) : (
                                                getButtonText(product.name)
                                            )}
                                        </button>
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default OnikanjiPricing;
