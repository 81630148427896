import React, { useRef, useState, useEffect } from 'react';
import { formatFuriganaText } from './readerFurigana';

export default function PodcastPlayer({
  transcript,
  audioUrl,
  handleSentenceClick,
  handleKanjiClick,
  showFurigana,
  fontSize
}) {
  const audioRef = useRef(null);
  const [currentLine, setCurrentLine] = useState(null);
  
  // Ref to keep track of the previous transcript startTime
  const prevStartTimeRef = useRef(null);

  // When the transcript (i.e. the page) changes, reset the audio's current time
  // only if the new first line's startTime differs from the previous one.
  useEffect(() => {
    if (audioRef.current && transcript.length > 0) {
      const newStartTime = transcript[0].startTime;
      if (prevStartTimeRef.current !== newStartTime) {
        audioRef.current.currentTime = newStartTime;
        prevStartTimeRef.current = newStartTime;
      }
    }
  }, [transcript]);

  // Global keydown listener to allow spacebar toggling the audio (play/pause)
  useEffect(() => {
    const handleSpacebar = (e) => {
      // Check if spacebar is pressed and that focus is not on an interactive element.
      const activeTag = document.activeElement?.tagName;
      if (e.code === "Space" && !["INPUT", "TEXTAREA", "BUTTON"].includes(activeTag)) {
        e.preventDefault();
        if (audioRef.current) {
          if (audioRef.current.paused) {
            audioRef.current.play();
          } else {
            audioRef.current.pause();
          }
        }
      }
    };

    window.addEventListener('keydown', handleSpacebar);
    return () => window.removeEventListener('keydown', handleSpacebar);
  }, []);

  // Clicking on a transcript container plays the entire line audio or pauses if already playing.
  const handleLineClick = (line) => {
    if (audioRef.current) {
      // If clicking the currently playing line, toggle pause/play
      if (currentLine === line.lineNumber && !audioRef.current.paused) {
        audioRef.current.pause();
      } else {
        audioRef.current.currentTime = line.startTime;
        audioRef.current.play();
        setCurrentLine(line.lineNumber);
      }
    }
  };

  // Plays the full line audio and updates the highlight.
  const playPodcastLineAudio = (line) => {
    if (audioRef.current) {
      // If clicking the currently playing line, toggle pause/play
      if (currentLine === line.lineNumber && !audioRef.current.paused) {
        audioRef.current.pause();
      } else {
        audioRef.current.currentTime = line.startTime;
        audioRef.current.play();
        setCurrentLine(line.lineNumber);
      }
    }
  };

  // When a sentence is clicked, pause the audio and call the callback.
  const onSentenceClick = (sentence, line, event) => {
    if (audioRef.current) {
      audioRef.current.pause();
    }
    if (handleSentenceClick) {
      handleSentenceClick(sentence, line, event);
    }
  };

  // Update the currently active transcript line for highlighting.
  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    const handleTimeUpdate = () => {
      const time = audio.currentTime;
      for (let i = transcript.length - 1; i >= 0; i--) {
        if (time >= transcript[i].startTime) {
          setCurrentLine(transcript[i].lineNumber);
          break;
        }
      }
    };

    audio.addEventListener('timeupdate', handleTimeUpdate);
    return () => audio.removeEventListener('timeupdate', handleTimeUpdate);
  }, [transcript]);

  return (
    <div className="podcast-player">
      {/* Audio player element */}
      <audio ref={audioRef} src={audioUrl} controls className="w-full" />

      {/* Transcript list */}
      <ul className="mt-4 space-y-2">
        {transcript.map((line, index) => (
          <li
            key={line.lineNumber || index}
            onClick={(e) => {
              // Play full-line audio unless the click originated from an inner element.
              if (e.target.closest('.not-audio')) return;
              handleLineClick(line);
            }}
            className={`cursor-pointer p-2 rounded hover:bg-gray-200 transition-all ${
              currentLine === line.lineNumber ? 'bg-blue-100' : ''
            }`}
          >
            <p
              className="text-gray-800 font-noto-sans-jp"
              style={{ fontSize }}
            >
              {formatFuriganaText(
                line.lineText,
                playPodcastLineAudio,
                false,
                onSentenceClick,
                handleKanjiClick,
                showFurigana,
                line,
                true
              )}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
}