import React, { useState, useRef, useEffect } from 'react';
import { BiHelpCircle, BiXCircle } from 'react-icons/bi';
import { FaTrophy, FaMedal, FaAward } from 'react-icons/fa';
import PropTypes from 'prop-types';

function Leaderboard({ currentPage, totalAccuracyData = [], speedRunData = [], streakData = [], onSeeMore = () => {}, isExpanded = false }) {
  const [activeTab, setActiveTab] = useState('Total Accuracy');
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);
  const [showOverlay, setShowOverlay] = useState(false);

  // Updated tabs array
  const tabs = ['Total Accuracy', 'Speed Run', 'Longest Streak'];

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };

  const closeOverlay = () => {
    setShowOverlay(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setShowTooltip(false);
      }
    };

    if (showTooltip) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showTooltip]);

  // Determine which data to display based on activeTab
  let leaderboardData = [];
  if (activeTab === 'Total Accuracy') {
    leaderboardData = totalAccuracyData;
  } else if (activeTab === 'Speed Run') {
    leaderboardData = speedRunData;
  } else if (activeTab === 'Longest Streak') {
    leaderboardData = streakData;
  }

  // Update overlay based on data availability
  useEffect(() => {
    setShowOverlay(leaderboardData.length === 0);
  }, [leaderboardData]);

  // Function to determine the icon based on rank
  const getIconForRank = (rank) => {
    switch (rank) {
      case 1:
        return <FaTrophy className="text-yellow-400" />;
      case 2:
        return <FaMedal className="text-gray-400" />;
      case 3:
        return <FaMedal className="text-yellow-600" />;
      default:
        return <FaAward className="text-green-500" />;
    }
  };

  // Format the score based on the active tab
  const formatScore = (item) => {
    if (activeTab === 'Total Accuracy') {
      // Convert accuracy value to percentage
      return `${(item.totalAccuracyValue * 100).toFixed(2)}%`;
    } else if (activeTab === 'Speed Run') {
      // Display total review minutes
      return `${item.totalReviewMinutes} mins`;
    } else if (activeTab === 'Longest Streak') {
      // Display streak days
      return `${item.currentStreak} days`;
    }
    return item.score; // Default case
  };

  const handleSeeMore = () => {
    if (onSeeMore) {
      onSeeMore();
    }
  };

  return (
    <div className="relative flex flex-col bg-white shadow-blueBoxShadow rounded-lg p-4 sm:p-6 w-full">
      {/* Help Icon */}
      <BiHelpCircle
        className="absolute top-2 right-2 text-2xl text-darkBlueColor cursor-pointer transition-transform hover:scale-110"
        onClick={toggleTooltip}
      />

      {/* Tooltip */}
      {showTooltip && (
        <div
          ref={tooltipRef}
          className="absolute top-10 right-2 bg-white border border-gray-300 rounded-lg p-4 shadow-lg z-50 max-w-xs"
        >
          <BiXCircle
            className="absolute top-2 right-2 text-xl text-gray-500 cursor-pointer"
            onClick={toggleTooltip}
          />
          <h3 className="text-gray-800 font-bold mb-2">Leaderboard Info</h3>
          <ul className="text-gray-600 text-sm list-disc list-inside space-y-1">
            <li>Navigate to "My Profile" to change your username.</li>
            <li>Total Accuracy: Overall accuracy across all levels.</li>
            <li>Speed Run: Users with the fastest review times across all levels.</li>
            <li>Longest Streak: Users with the longest streak of consecutive days of reviewing.</li>
            <li>Rankings are refreshed once daily at 12am EST.</li>
          </ul>
        </div>
      )}

<div className="text-2xl sm:text-3xl font-bold mb-4 text-black">Leaderboard</div>

{/* Tabs */}
<div className="flex flex-col sm:flex-row w-full mb-4">
  {tabs.map((tab) => (
    <div
      key={tab}
      className={`flex-1 p-2 sm:p-3 border border-darkBlueColor rounded-lg mb-2 sm:mb-0 sm:mx-1 cursor-pointer transition-colors duration-300 text-center ${
        activeTab === tab
          ? 'bg-darkBlueColor text-white'
          : 'bg-white text-darkBlueColor hover:bg-darkBlueColor hover:text-white'
      }`}
      onClick={() => handleTabClick(tab)}
    >
      <div className="text-md font-semibold whitespace-nowrap">{tab}</div>
    </div>
  ))}
</div>

{/* Leaderboard Section */}
<div className="relative w-full">
  {leaderboardData.length === 0 ? (
    <div className="text-center text-gray-500 py-4">No data available.</div>
  ) : (
    <div className="w-full overflow-x-auto">
      {/* Header Row */}
      <div className="min-w-max">
        <div className="flex items-center p-3 rounded-t-lg bg-darkOrangeColor text-white font-semibold">
          <span className="w-6 sm:w-8 text-center"></span>
          <span className="flex-grow pl-2 text-left">Username</span>
          <span className="w-20 sm:w-32 text-center">Current Level</span>
          <span className="w-20 sm:w-24 text-center">
            {activeTab === 'Total Accuracy' 
              ? 'Accuracy' 
              : activeTab === 'Longest Streak' 
                ? 'Days' 
                : 'Minutes'}
          </span>
        </div>
        {/* Data Rows */}
        <div className="flex flex-col w-full">
          {leaderboardData.map((item, index) => (
            <div
              key={item.position}
              className={`flex items-center p-3 ${
                index === leaderboardData.length - 1 ? 'rounded-b-lg' : ''
              } transition-colors duration-200 ${
                index % 2 === 0 ? 'bg-gray-100' : 'bg-white'
              } hover:bg-gray-200`}
            >
              <span className="font-semibold w-6 sm:w-8 text-gray-700 text-center">
                {getIconForRank(item.position)}
              </span>
              <span className="flex-grow font-medium text-gray-800 pl-2 text-left">
                {item.publicUsername}
              </span>
              <span className="w-20 sm:w-32 text-center text-gray-700">{item.currentLevel}</span>
              <span className="w-20 sm:w-24 text-center font-semibold text-gray-700">
                {formatScore(item)}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )}
</div>

{/* See More Link */}
<div className="text-center mt-4">
  {currentPage === "home" ? (
    <button 
      onClick={() => window.location.href = '/my-stats'}
      className="text-darkBlueColor hover:underline text-sm font-medium"
    >
      See More
    </button>
  ) : (
    <button 
      onClick={handleSeeMore}
      className="text-darkBlueColor hover:underline text-sm font-medium"
    >
      {isExpanded ? 'Show Less' : 'See More'}
    </button>
  )}
</div>
</div>
);
}

Leaderboard.propTypes = {
  currentPage: PropTypes.string.isRequired,
  totalAccuracyData: PropTypes.array,
  speedRunData: PropTypes.array,
  streakData: PropTypes.array,
  onSeeMore: PropTypes.func,
  isExpanded: PropTypes.bool,
};

export default Leaderboard;
