import { useQuery } from '@tanstack/react-query';
import { getFirestore, collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { useUser } from '../../components/utils/UserContext';
import { useUserLevel } from '../../extraStudy/hooks/getUserLevel';
import { getLevelKanjiPercentage } from './calculateKanjiCoverage';

/**
 * Fetch all the user's content titles from:
 *   /immersionContentUserProgress/<USER_ID>/contentTitles/<bookTitleDoc>
 */
const fetchUserContentProgress = async (userId, userLevel) => {
    if (!userId) throw new Error('No userId provided to fetchUserContentProgress');

    const db = getFirestore();
    
    try {
    
        // 1) Reference the contentTitles collection
        const contentTitlesRef = collection(db, 'immersionContentUserProgress', userId, 'contentTitles');

        // 2) Get all docs in that collection
        const snapshot = await getDocs(contentTitlesRef);

        if (snapshot.empty) {
            // console.log('No contentTitles found for user:', userId);
            return [];
        }

        // Prepare array of progress data
        const progressData = [];

        // 3) For each document in contentTitles
        for (const docSnap of snapshot.docs) {
            const title = docSnap.id;        
            const progressFields = docSnap.data();  

            // 4) Get the corresponding content details from immersionContent
            const contentRef = doc(db, 'immersionContent', title);
            const contentSnap = await getDoc(contentRef);
            const contentData = contentSnap.exists() ? contentSnap.data() : null;

            // Calculate kanji percentage based on user level using the content data
            const levelKanjiPercentage = getLevelKanjiPercentage(
                contentData?.onikanjiLevelToContentKanji,
                userLevel
            );

            progressData.push({
                title,
                progress: {
                    position: progressFields.position || 1,
                    chapter: progressFields.chapter || 1,
                    active: progressFields.active || false,
                    favorited: progressFields.favorited || false,
                    knownKanji: progressFields.knownKanji || 0,
                    lastUpdated: progressFields.lastUpdated || null,
                    levelKanjiPercentage,
                    userLevel,
                    rating: progressFields.rating || 0
                },
                content: {
                    ...contentData,
                    type: contentData?.type || 'lightNovel'
                }
            });
        }

        // console.log('Final progressData:', progressData);
        return progressData;

    } catch (err) {
        console.error('Error fetching user content progress:', err);
        console.error('Error details:', {
            message: err.message,
            code: err.code,
            stack: err.stack
        });
        throw err;
    }
};

// The hook that uses the fetch function
export const useUserContentProgress = () => {
    const { user } = useUser();
    const { data: userLevel } = useUserLevel(user?.uid);

    const query = useQuery({
        queryKey: ['userContentProgress', user?.uid, userLevel],
        queryFn: async () => {
            if (!user) {
                throw new Error('User must be authenticated');
            }
            return fetchUserContentProgress(user.uid, userLevel);
        },
        enabled: !!user,
        staleTime: 0,  // Changed to 0 to allow immediate refetches
        cacheTime: 3_600_000, // 1 hour
        refetchOnMount: true, // Added to ensure refetch on mount
        refetchOnWindowFocus: true, // Added to refetch when window regains focus
    });

    return query;
};

export default useUserContentProgress;
