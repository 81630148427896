import React, { useState, useRef, useEffect } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { getAuth, createUserWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { googleProvider } from "../config/firebase";
import { createCallableFunction } from '../config/firebase';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { useUser } from '../components/utils/UserContext';
import oniKanjiLoginImage from '../images/landingPageAssets/Mikan-chan-OniKanji.png';
import OniKanjiLogo from '../images/landingPageAssets/onikanji-logo.png';

const updateLastLoginDate = createCallableFunction('updateLastLoginDate');
const saveReferralData = createCallableFunction('saveReferralData');

const OniKanjiSignUp = () => {
  const { user, loading: userLoading } = useUser();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [signupError, setSignupError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [navigating, setNavigating] = useState(false);
  const [message, setMessage] = useState("");
  const lastSignUpAttempt = useRef(0);
  const [loadingMessage, setLoadingMessage] = useState('Setting up your account...');
  const [mathProblem, setMathProblem] = useState({ num1: 0, num2: 0, answer: "" });
  const [userAnswer, setUserAnswer] = useState("");

  // Remove email verification state and related UI logic.

  useEffect(() => {
    document.title = "OniKanji - Sign Up";

    if (userLoading) return;

    if (user) {
      setNavigating(true);
      // 5-second delay before navigating to the home screen.
      const timer = setTimeout(() => {
        navigate('/home');
        setNavigating(false);
      }, 5000);

      return () => {
        clearTimeout(timer);
        setNavigating(false);
      };
    }
  }, [user, userLoading, navigate]);

  useEffect(() => {
    // Check localStorage when component mounts
    const referrer = localStorage.getItem('referrer');
    const utmSource = localStorage.getItem('utm_source');
    const campaign = localStorage.getItem('campaign');

    console.log('Checking localStorage on signup page:', {
      referrer,
      utmSource,
      campaign
    });
  }, []); // Run once when component mounts

  useEffect(() => {
    if (userLoading || isLoading || navigating) {
      const messages = [
        'Setting up your account...',
        'Getting things ready...',
        'Tidying up...'
      ];
      let currentIndex = 0;

      const messageInterval = setInterval(() => {
        currentIndex = (currentIndex + 1) % messages.length;
        setLoadingMessage(messages[currentIndex]);
      }, 2000);

      return () => clearInterval(messageInterval);
    }
  }, [userLoading, isLoading, navigating]);

  // Generate a new math problem
  const generateMathProblem = () => {
    const num1 = Math.floor(Math.random() * 10);
    const num2 = Math.floor(Math.random() * 10);
    setMathProblem({ num1, num2, answer: (num1 + num2).toString() });
    setUserAnswer("");
  };
  
  // Generate a math problem when component mounts
  useEffect(() => {
    generateMathProblem();
  }, []);

  if (userLoading || isLoading || navigating) {
    return (
      <div className="min-h-screen flex flex-col justify-center items-center bg-mainBackgroundColor gap-4">
        <ClipLoader size={50} color="#009BCE" />
        <p className="text-gray-600 text-lg">{loadingMessage}</p>
      </div>
    );
  }

  // Removed the block that shows the "Email Verification Required" screen.

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePassword = (password) => {
    const re = /^[a-zA-Z0-9!@#$%^&*()_+\-={};':"\\|,.<>/?]{6,}$/;
    return re.test(password);
  };

  const handleSignUp = async (e) => {
    e.preventDefault();

    // Verify math problem answer
    if (userAnswer !== mathProblem.answer) {
      setSignupError("Please solve the math problem correctly.");
      generateMathProblem(); // Generate a new problem
      return;
    }

    const referrer = localStorage.getItem('referrer');
    const utmSource = localStorage.getItem('utm_source');
    const campaign = localStorage.getItem('campaign');
    const hasReferralData = referrer || utmSource || campaign;

    const now = Date.now();
    if (now - lastSignUpAttempt.current < 10000) {
      toast.error("Please wait 10 seconds before trying again.");
      return;
    }
    lastSignUpAttempt.current = now;

    setSignupError("");
    setMessage("");
    setIsLoading(true);

    if (!validateEmail(email)) {
      setSignupError("Please enter a valid email address.");
      setIsLoading(false);
      return;
    }

    if (!validatePassword(password)) {
      setSignupError("Password must be at least 6 characters long and contain only alphanumeric characters and symbols.");
      setIsLoading(false);
      return;
    }

    const auth = getAuth();
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const currentUser = userCredential.user;
      
      // Update user setup data without sending a verification email.
      await updateLastLoginDate(currentUser.uid);

      if (hasReferralData) {
        try {
          await saveReferralData({ referrer, utmSource, campaign });
        } catch (error) {
          console.error('Error saving referral data:', error);
        }
        // Clean up localStorage
        localStorage.removeItem('referrer');
        localStorage.removeItem('utm_source');
        localStorage.removeItem('campaign');
      }

      setMessage("Account created! Redirecting to the home screen...");
      setEmail("");
      setPassword("");
      setIsLoading(false);
      // Navigation will occur via the useEffect after 5 seconds

    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        setSignupError("Email is already in use.");
      } else {
        setSignupError(`Error: ${error.message}`);
      }
      setIsLoading(false);
    }
  };

  const signUpWithGoogle = async () => {
    // Verify math problem answer
    if (userAnswer !== mathProblem.answer) {
      setSignupError("Please solve the math problem correctly.");
      generateMathProblem(); // Generate a new problem
      return;
    }

    const referrer = localStorage.getItem('referrer');
    const utmSource = localStorage.getItem('utm_source');
    const campaign = localStorage.getItem('campaign');
    console.log('Signup Referral Data:', { referrer, utmSource, campaign });
    const hasReferralData = referrer || utmSource || campaign;

    const now = Date.now();
    if (now - lastSignUpAttempt.current < 10000) {
      toast.error("Please wait 10 seconds before trying again.");
      return;
    }
    lastSignUpAttempt.current = now;

    setSignupError("");
    setIsLoading(true);
    try {
      const auth = getAuth();
      const result = await signInWithPopup(auth, googleProvider);

      await updateLastLoginDate(result.user.uid);
      if (hasReferralData) {
        try {
          await saveReferralData({ referrer, utmSource, campaign });
        } catch (error) {
          console.error('Error saving referral data:', error);
        }
        localStorage.removeItem('referrer');
        localStorage.removeItem('utm_source');
        localStorage.removeItem('campaign');
      }

      setIsLoading(false);
      // Navigation will occur via the useEffect after the user is set
    } catch (error) {
      if (error.code === 'auth/popup-closed-by-user') {
        window.location.reload();
      } else {
        setSignupError("Failed to sign up with Google. Please try again.");
      }
      setIsLoading(false);
    }
  };

  return (
    <div className='min-h-screen flex justify-center items-center bg-mainBackgroundColor p-4'>
      <div className='bg-white rounded-lg shadow-blueBoxShadow flex flex-col lg:flex-row w-full max-w-6xl'>
        <div className='w-full lg:w-1/2 h-64 lg:h-auto flex-shrink-0 bg-gray-200'>
          <img 
            src={oniKanjiLoginImage} 
            alt="Onikanji Login" 
            className='w-full h-full object-cover lg:object-cover rounded-t-lg lg:rounded-l-lg lg:rounded-tr-none' 
          />
        </div>
        <div className='w-full lg:w-1/2 p-8 flex flex-col justify-center'>
          <img 
            src={OniKanjiLogo} 
            alt="Onikanji Logo" 
            className='mb-6 mx-auto h-20 cursor-pointer' 
            onClick={() => window.location.href = '/'} 
          />
          <h2 className='text-2xl font-bold mb-6 text-center text-logoColor'>Sign Up for Onikanji</h2>
          <form className='space-y-4' onSubmit={handleSignUp}>
            <div>
              <label htmlFor="email" className='block text-sm font-medium text-gray-700'>Email address</label>
              <input 
                type="email" 
                id="email" 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required 
                className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-logoColor focus:border-logoColor' 
                autoComplete="email"
              />
            </div>
            <div className="relative">
              <label htmlFor="password" className='block text-sm font-medium text-gray-700'>Password</label>
              <input 
                type={showPassword ? "text" : "password"} 
                id="password" 
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required 
                className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-logoColor focus:border-logoColor pr-10'
                autoComplete="new-password"
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 mt-6"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? (
                  <FaEyeSlash className="h-5 w-5 text-gray-500" />
                ) : (
                  <FaEye className="h-5 w-5 text-gray-500" />
                )}
              </button>
            </div>
            
            {/* Add math problem verification */}
            <div>
              <label htmlFor="mathAnswer" className='block text-sm font-medium text-gray-700'>
                Human Check: What is {mathProblem.num1} + {mathProblem.num2}?
              </label>
              <input 
                type="text" 
                id="mathAnswer"
                value={userAnswer}
                onChange={(e) => setUserAnswer(e.target.value)}
                required 
                className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-logoColor focus:border-logoColor' 
              />
            </div>
            
            {signupError && (
              <div className="mt-1 text-sm text-red-600">
                {signupError}
              </div>
            )}
            {message && (
              <div className="mt-4 text-lg font-semibold text-green-600 text-center p-4 bg-green-100 rounded-md">
                {message}
              </div>
            )}
            <button 
              type="submit" 
              className='w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-darkOrangeColor hover:bg-darkOrangeColorButtonHover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-logoColor'
              disabled={isLoading} 
            >
              {isLoading ? (
                <>
                  <ClipLoader size={16} color="#009BCE" className="mr-2" />
                  Signing up...
                </>
              ) : (
                'Sign up'
              )}
            </button>
          </form>
          <div className='mt-6'>
            <div className='relative'>
              <div className='absolute inset-0 flex items-center'>
                <div className='w-full border-t border-gray-300'></div>
              </div>
              <div className='relative flex justify-center text-sm'>
                <span className='px-2 bg-white text-gray-500'>Or continue with</span>
              </div>
            </div>
            <div className='mt-6'>
              <button 
                onClick={signUpWithGoogle}
                disabled={isLoading}
                className='w-full flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-logoColor'
              >
                {isLoading ? (
                  <>
                    <ClipLoader size={16} color="#009BCE" className="mr-2" />
                    Signing up...
                  </>
                ) : (
                  'Sign up with Google'
                )}
              </button>
            </div>
          </div>
          <div className='mt-6 text-center'>
            <div className='text-sm'>
              <a href="/login" className='font-medium text-logoColor hover:text-darkOrangeColor'>
                Already have an account? Log in
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OniKanjiSignUp;
