import React from 'react';

const statusColors = {
  Awakened: '#DFA06A',
  Explorer: '#009BCE',
  Mastery: '#DFA06A',
  Ascension: '#009BCE',
  Slayed: '#DFA06A',
  Cooked: '#10B981'  // Emerald-500 color for Cooked status
};

const KanjiStatusCircles = ({ queStatusCounts }) => {
  const statuses = ['Awakened', 'Explorer', 'Mastery', 'Ascension', 'Slayed', 'Cooked'];

  return (
    <div className="max-w-screen-xl w-full mx-auto bg-white shadow-blueBoxShadow rounded-lg p-4 sm:p-6 mt-6 sm:mt-10">
      <h2 className="text-2xl sm:text-3xl font-bold text-black mb-4 sm:mb-6 text-center sm:text-left">Your Kanji Status</h2>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-6 gap-4 sm:gap-6">
        {statuses.map((status) => (
          <div key={status} className="flex flex-col items-center">
            <div 
              className="w-16 h-16 sm:w-20 sm:h-20 md:w-24 md:h-24 rounded-full flex flex-col items-center justify-center"
              style={{ backgroundColor: statusColors[status] }}
            >
              <div className="w-14 h-14 sm:w-16 sm:h-16 md:w-20 md:h-20 rounded-full bg-white flex flex-col items-center justify-center">
                <p className="text-xl sm:text-2xl md:text-3xl font-bold">{queStatusCounts[status] || 0}</p>
              </div>
            </div>
            <p className="mt-2 text-center font-semibold text-sm sm:text-base">{status}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default KanjiStatusCircles;