import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { HiMenu, HiX } from 'react-icons/hi';
import { BsChevronDown } from "react-icons/bs";

import { useUser } from '../components/utils/UserContext';

import logoImage from '../images/landingPageAssets/onikanji-logo.png';
import mikanChanFloatingMainGraphic from '../images/landingPageAssets/Mikan-chan-OniKanji.png';

// Selling Points
import sellingPointOne from '../images/landingPageAssets/onikanji-selling-point-one.webp';
import sellingPointTwo from '../images/landingPageAssets/onikanji-selling-point-two.webp';
import sellingPointThree from '../images/landingPageAssets/onikanji-selling-point-three.webp';

// Background Image
import backgroundImage from '../images/landingPageAssets/onikanji-clouds-background.png';

import Footer from '../components/footer';

import learnImage from '../images/landingPageAssets/onikanji-learn-graphic.webp';
import correctReviewImage from '../images/landingPageAssets/onikanji-correct-review.png';
import trackStatsImage from '../images/landingPageAssets/onikanji-tracks-stats.png';
import levelUpImage from '../images/landingPageAssets/onikanji-level-up-landing-page.webp';
import competeGraphic from '../images/landingPageAssets/onikanji-leaderboard-graphic.png';

const OniKanjiLandingPage = () => {

    // CDN Images
    const godzillaJoinUsImage = "https://firebasestorage.googleapis.com/v0/b/kf-demo-project-b0508.appspot.com/o/assets%2FlandingPageAssets%2Fonikanji-godzilla-join-us-desktop.webp?alt=media&token=06b623fa-f7be-4299-b8ec-01edc241348a";
    const godzillaJoinUsImageMobile = "https://firebasestorage.googleapis.com/v0/b/kf-demo-project-b0508.appspot.com/o/assets%2FlandingPageAssets%2Fonikanji-godzilla-join-us-mobile.webp?alt=media&token=1a48c679-7473-46fb-9313-62bb9ca3adbc";
    // Pricing Images
    const pricingFishImage = "https://firebasestorage.googleapis.com/v0/b/kf-demo-project-b0508.appspot.com/o/assets%2FpricingAssets%2Fonikanji-monthly-sub.png?alt=media&token=c68c41b4-7e2e-405f-8bf1-0953defed5db";
    const pricingBonsaiImage = "https://firebasestorage.googleapis.com/v0/b/kf-demo-project-b0508.appspot.com/o/assets%2FpricingAssets%2Fonikanji-yearly-sub.png?alt=media&token=4f9ed37f-5f69-4698-bf14-68e130d37f16";
    const pricingOniMaskImage = "https://firebasestorage.googleapis.com/v0/b/kf-demo-project-b0508.appspot.com/o/assets%2FpricingAssets%2Fonikanji-lifetime-sub.png?alt=media&token=4e42ad49-fa03-4a0d-b236-0d8da83ff656";

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef(null);
    const { user, loading } = useUser();
    const location = useLocation();

    useEffect(() => {
        document.title = "OniKanji - Learn Kanji Like a Native: In-context";
    }, []);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const ref = queryParams.get('ref');
        const utmSource = queryParams.get('utm_source');
        const campaign = queryParams.get('campaign');
        
        if (ref) {
            localStorage.setItem('referrer', ref);
        }
        if (utmSource) {
            localStorage.setItem('utm_source', utmSource);
        }
        if (campaign) {
            localStorage.setItem('campaign', campaign);
        }
    }, [location]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="font-noto-sans-jp bg-[#fae9d9] relative">
            <div className="fixed inset-0 z-0 backgroundImage"></div>
            <div className="relative z-10">

                <nav className="fixed top-0 left-0 right-0 z-50 bg-white p-2 md:p-4 flex justify-between items-center shadow-md">
                    {/* Logo and Version */}
                    <div className="flex items-center">
                        <img
                            src={logoImage}
                            alt="Onikanji Logo"
                            className="h-10 sm:h-12 md:h-14 lg:h-18 ml-2 md:ml-4 lg:ml-20"
                        />
                        <div className="inline-block bg-oniKanjiPink text-white text-xs rounded-full px-2 py-1 ml-2">
                            Beta v1.1.2
                        </div>
                    </div>
                    {/* Menu Items */}
                    <div className="hidden lg:flex justify-center items-center space-x-4 xl:space-x-6 mr-4 xl:mr-20">
                        <button
                            onClick={() => document.getElementById('onikanji-method').scrollIntoView({ behavior: 'smooth' })}
                            className="text-black text-base xl:text-lg font-semibold hover:text-gray-600 transition duration-300"
                        >
                            Our Method
                        </button>
                        <button
                            onClick={() => document.getElementById('pricing-section').scrollIntoView({ behavior: 'smooth' })}
                            className="text-black text-base xl:text-lg font-semibold hover:text-gray-600 transition duration-300"
                        >
                            Pricing
                        </button>
                        <a
                            href="https://discord.gg/7TEG9cqZTP"
                            className="text-black text-base xl:text-lg font-semibold hover:text-gray-600 transition duration-300"
                        >
                            Community
                        </a>
                        <a
                            href="https://knowledge.onikanji.com/"
                            className="text-black text-base xl:text-lg font-semibold hover:text-gray-600 transition duration-300"
                        >
                            Learn More
                        </a>
                        {!loading &&
                            (user ? (
                                <a
                                    href="/home"
                                    className="text-black text-base xl:text-lg font-semibold hover:text-gray-600 transition duration-300"
                                >
                                    Launch App
                                </a>
                            ) : (
                                <a
                                    href="/login"
                                    className="text-black text-base xl:text-lg font-semibold hover:text-gray-600 transition duration-300"
                                >
                                    Sign In
                                </a>
                            ))}
                        <a
                            href="/signup"
                            className="border-2 border-logoColor hover:border-[#015f80] text-white bg-logoColor text-base xl:text-lg font-bold py-2 px-4 rounded-lg hover:bg-[#015f80] transition duration-300"
                        >
                            Try OniKanji
                        </a>
                    </div>
                    {/* Mobile Menu Button */}
                    <button
                        className="lg:hidden text-black text-2xl p-2"
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                        aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
                    >
                        {isMenuOpen ? <HiX /> : <HiMenu />}
                    </button>
                    {/* Mobile Menu Popup */}
                    {isMenuOpen && (
                        <div
                            ref={menuRef}
                            className="lg:hidden absolute top-full left-0 right-0 bg-white shadow-lg py-4"
                        >
                            <div className="flex flex-col space-y-4 px-6 text-center">
                                <button
                                    onClick={() => {
                                        document.getElementById('onikanji-method').scrollIntoView({ behavior: 'smooth' });
                                        setIsMenuOpen(false);
                                    }}
                                    className="text-black text-lg font-semibold hover:text-gray-600 transition duration-300"
                                >
                                    Our Method
                                </button>
                                <button
                                    onClick={() => {
                                        document.getElementById('pricing-section').scrollIntoView({ behavior: 'smooth' });
                                        setIsMenuOpen(false);
                                    }}
                                    className="text-black text-lg font-semibold hover:text-gray-600 transition duration-300"
                                >
                                    Pricing
                                </button>
                                <a
                                    href="https://discord.gg/7TEG9cqZTP"
                                    className="text-black text-lg font-semibold hover:text-gray-600 transition duration-300"
                                >
                                    Community
                                </a>
                                <a
                                    href="https://knowledge.onikanji.com/"
                                    className="text-black text-lg font-semibold hover:text-gray-600 transition duration-300"
                                >
                                    Learn More
                                </a>
                                {!loading && (user ? (
                                    <a
                                        href="/home"
                                        className="text-black text-lg font-semibold hover:text-gray-600 transition duration-300"
                                    >
                                        Launch App
                                    </a>
                                ) : (
                                    <a
                                        href="/login"
                                        className="text-black text-lg font-semibold hover:text-gray-600 transition duration-300"
                                    >
                                        Sign In
                                    </a>
                                ))}
                                <a
                                    href="/signup"
                                    className="border-2 border-logoColor hover:border-[#015f80] text-white bg-logoColor text-lg font-bold py-2 px-4 rounded-lg hover:bg-[#015f80] transition duration-300 text-center"
                                >
                                    Try OniKanji
                                </a>
                            </div>
                        </div>
                    )}
                </nav>

                {/* Full-screen Hero Section */}
                <div className="min-h-screen w-full bg-white flex flex-col items-center justify-center relative pt-24 md:pt-32">
                    <div className="container mx-auto px-4 flex-1 flex items-center">
                        <div className="flex flex-col lg:flex-row items-center justify-center w-full py-8">
                            {/* Right side - Mikan-chan graphic */}
                            <div className="lg:w-1/2 flex justify-center items-center order-1 lg:order-2">
                                <img
                                    src={mikanChanFloatingMainGraphic}
                                    alt="Mikan-chan"
                                    className="w-auto max-w-full h-auto max-h-[40vh] md:max-h-[60vh] animate-float hover:scale-105 transition-transform duration-300"
                                    style={{
                                        animation: `float 3s ease-in-out infinite`,
                                        '@keyframes float': {
                                            '0%, 100%': { transform: 'translateY(0)' },
                                            '50%': { transform: 'translateY(-20px)' },
                                        },
                                    }}
                                />
                            </div>

                            {/* Left side - Text content */}
                            <div className="lg:w-1/2 text-center lg:text-left mb-4 lg:mb-0 order-2 lg:order-1 px-4 lg:px-12">
                                <h2 className="text-3xl md:text-5xl font-bold mb-4 md:mb-6 text-logoColor leading-tight">
                                    Learn Kanji Like a Native:
                                    <span className="block mt-2">In-context</span>
                                </h2>
                                <p className="text-lg md:text-2xl text-gray-700 mb-8 md:mb-12 font-light">
                                    Master kanji naturally with our smart, context-based learning system.
                                </p>
                                <div className="flex flex-col sm:flex-row gap-4 max-w-md mx-auto lg:mx-0">
                                    <a
                                        href="/signup"
                                        className="group relative inline-block bg-darkOrangeColor text-white font-bold py-4 px-8 rounded-lg transition-all duration-300 hover:bg-darkOrangeColorButtonHover hover:text-lightOrangeText shadow-sm hover:shadow-md"
                                    >
                                        <span className="relative z-10">Get Started</span>
                                    </a>
                                    <a
                                        href="/home"
                                        className="group inline-block bg-transparent border-2 border-logoColor text-logoColor font-bold py-4 px-8 rounded-lg transition-all duration-300 hover:bg-logoColor hover:text-white"
                                    >
                                        Go To Dashboard
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Chevron - Hidden below lg breakpoint */}
                    <div className="absolute bottom-8 w-full hidden lg:flex justify-center">
                        <BsChevronDown
                            className="text-logoColor text-4xl animate-bounce cursor-pointer"
                            onClick={() => window.scrollTo({
                                top: window.innerHeight,
                                behavior: 'smooth'
                            })}
                        />
                    </div>
                </div>

                {/* Selling Points Section */}
                <div className="w-full py-8 md:py-16 px-4 md:px-8 relative">
                    <div className="max-w-6xl mx-auto p-6 md:p-10">
                        <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold text-logoColor mb-6 md:mb-10 text-center">
                            A Context-First Approach to Learning Kanji
                        </h2>

                        {/* First Selling Point */}
                        <div className="flex flex-col md:flex-row mb-4 md:mb-8">
                            <div className="w-full md:w-1/2 px-4 flex flex-col justify-center mb-4 md:mb-0">
                                <h3 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 md:mb-6 text-logoColor">
                                    Unlock Native Content with Ease
                                </h3>
                                <p className="text-left text-base md:text-lg lg:text-xl mb-4">
                                    Experience a native-like kanji journey with OniKanji's
                                    authentic curriculum, designed to mirror how native children
                                    learn.
                                </p>
                                <p className="text-left text-base md:text-lg lg:text-xl">
                                    Master all 2,000+ common Joyo Kanji and thousands of
                                    vocabulary words through calculated, context-rich lessons.
                                </p>
                            </div>
                            <div className="w-full md:w-1/2 px-4 flex items-center justify-center">
                                <img
                                    src={sellingPointOne}
                                    alt="Mikan-chan sitting"
                                    className="w-full max-w-md h-auto"
                                />
                            </div>
                        </div>

                        {/* Second Selling Point */}
                        <div className="flex flex-col md:flex-row-reverse mb-4 md:mb-8">
                            <div className="w-full md:w-1/2 px-4 flex flex-col justify-center mb-4 md:mb-0">
                                <h3 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 md:mb-6 text-logoColor">
                                    Personalized Learning, Powered By You
                                </h3>
                                <p className="text-left text-base md:text-lg lg:text-xl mb-4">
                                    Experience learning that's as unique as you are.
                                </p>
                                <p className="text-left text-base md:text-lg lg:text-xl mb-4">
                                    OniKanji's advanced machine learning algorithms analyze your
                                    behavior to create a custom learning path. We adjust
                                    difficulty, pacing, and content in real-time, ensuring you
                                    learn efficiently and effectively.
                                </p>
                                <p className="text-left text-base md:text-lg lg:text-xl">
                                    Our goal? To make you proficient in Kanji so quickly, you'll
                                    outgrow us sooner than you think.
                                </p>
                            </div>
                            <div className="w-full md:w-1/2 px-4 flex items-center justify-center">
                                <img
                                    src={sellingPointTwo}
                                    alt="Mikan-chan walking"
                                    className="w-full max-w-md h-auto"
                                />
                            </div>
                        </div>

                        {/* Third Selling Point */}
                        <div className="flex flex-col md:flex-row">
                            <div className="w-full md:w-1/2 px-4 flex flex-col justify-center mb-4 md:mb-0">
                                <h3 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 md:mb-6 text-logoColor">
                                    Dominate Your Kanji Journey with Advanced Tools
                                </h3>
                                <p className="text-left text-base md:text-lg lg:text-xl mb-4">
                                    OniKanji transforms your learning journey into an engaging,
                                    data-driven experience.
                                </p>
                                <p className="text-left text-base md:text-lg lg:text-xl mb-4">
                                    Master kanji like never before with full stat tracking,
                                    real-time JLPT completion rates, and detailed progress reports
                                    for every level.
                                </p>
                                <p className="text-left text-base md:text-lg lg:text-xl">
                                    Challenge yourself with high scores, compete with peers on
                                    leaderboards, or focus your studies with custom lists tailored
                                    to your goals—like acing the JLPT.
                                </p>
                            </div>
                            <div className="w-full md:w-1/2 px-4 flex items-center justify-center">
                                <img
                                    src={sellingPointThree}
                                    alt="Mikan-chan running"
                                    className="w-full max-w-md h-auto"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-full py-12 md:py-20 px-6 md:px-10">
                    <div className="max-w-6xl mx-auto p-6 sm:p-8 md:p-10 lg:p-14 bg-white shadow-lg rounded-lg">
                        <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold text-logoColor mb-6 sm:mb-8 md:mb-12 text-center">
                            Use Your Kanji Knowledge to Read Manga, Light Novels, and More
                        </h2>
                        <p className="text-lg md:text-xl lg:text-2xl text-center mb-6 md:mb-8 text-gray-700">
                            Quickly unlock native content with Onikanji's immersive eReader. Put your kanji knowledge to the test with one-click translations for meaning, grammar, context, and more.
                        </p>
                        <div className="relative w-full mx-auto overflow-hidden rounded-lg shadow-md transition-all duration-300 hover:scale-105">
                            <img 
                                src="https://firebasestorage.googleapis.com/v0/b/kf-demo-project-b0508.appspot.com/o/assets%2FlandingPageAssets%2Fonikanji-immersion-landing-page.png?alt=media&token=70ab1c66-81d2-4d3d-85e9-611d8c9f017b"
                                alt="Immersion Landing Page"
                                className="w-full h-auto object-cover" 
                            />
                        </div>
                    </div>
                </div>




                {/* The OniKanji Method Section */}
                <div id="onikanji-method" className="w-full py-8 md:py-16 px-4 md:px-8">
                    <div className="max-w-6xl mx-auto p-6 md:p-10">
                        <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold text-logoColor mb-6 md:mb-10 text-center">The OniKanji Method</h2>
                        <div className="w-full md:w-4/5 mx-auto mb-10">
                            <p className="text-lg md:text-xl lg:text-2xl text-center font-semibold">
                                Master Kanji through context, advanced learning algorithms, and intelligent tracking
                            </p>
                        </div>

                        <div className="flex flex-col md:flex-row mb-12 md:mb-16">
                            <div className="w-full md:w-1/2 px-4 flex flex-col justify-center mb-6 md:mb-0">
                                <h3 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 md:mb-6">Learn</h3>
                                <p className="text-left text-base md:text-lg lg:text-xl">
                                    Discover kanji in meaningful contexts, enhancing your understanding and retention.
                                </p>
                            </div>
                            <div className="w-full md:w-1/2 px-4 flex items-center justify-center">
                                <div className="w-full max-w-lg h-auto overflow-hidden rounded-lg shadow-lg transition-all duration-300 hover:scale-150">
                                    <img src={learnImage} alt="OniKanji Learn Section" className="w-full h-auto" />
                                </div>
                            </div>
                        </div>

                        {/* Review Section */}
                        <div className="flex flex-col md:flex-row-reverse mb-12 md:mb-16">
                            <div className="w-full md:w-1/2 px-4 flex flex-col justify-center mb-6 md:mb-0">
                                <h3 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 md:mb-6">Review</h3>
                                <p className="text-left text-base md:text-lg lg:text-xl">
                                    Reinforce your knowledge with super-charged spaced repetition and contextual hints.
                                </p>
                            </div>
                            <div className="w-full md:w-1/2 px-4 flex items-center justify-center">
                                <div className="w-full max-w-lg h-auto overflow-hidden rounded-lg shadow-lg transition-all duration-300 hover:scale-150">
                                    <img src={correctReviewImage} alt="Correct Review" className="w-full h-auto" />
                                </div>
                            </div>
                        </div>

                        {/* Grow Section */}
                        <div className="flex flex-col md:flex-row mb-12 md:mb-16">
                            <div className="w-full md:w-1/2 px-4 flex flex-col justify-center mb-6 md:mb-0">
                                <h3 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 md:mb-6">Grow</h3>
                                <p className="text-left text-base md:text-lg lg:text-xl">
                                    Track your progress and watch your kanji knowledge expand over time.
                                </p>
                            </div>
                            <div className="w-full md:w-1/2 px-4 flex items-center justify-center">
                                <div className="w-full max-w-lg h-auto overflow-hidden rounded-lg shadow-lg transition-all duration-300 hover:scale-150">
                                    <img src={trackStatsImage} alt="OniKanji Tracks Stats" className="w-full h-auto" />
                                </div>
                            </div>
                        </div>

                        {/* Celebrate Section */}
                        <div className="flex flex-col md:flex-row-reverse">
                            <div className="w-full md:w-1/2 px-4 flex flex-col justify-center mb-6 md:mb-0">
                                <h3 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 md:mb-6">Celebrate</h3>
                                <p className="text-left text-base md:text-lg lg:text-xl">
                                    Look back on your progress, celebrate your wins, and share your achievements with friends. Every milestone in your kanji journey is a cause for celebration!
                                </p>
                            </div>
                            <div className="w-full md:w-1/2 px-4 flex items-center justify-center">
                                <div className="w-full max-w-lg h-auto overflow-hidden rounded-lg shadow-lg transition-all duration-300 hover:scale-150">
                                    <img src={levelUpImage} alt="Level Up Celebration" className="w-full h-auto" />
                                </div>
                            </div>
                        </div>

                        {/* Compete Section */}
                        <div className="flex flex-col md:flex-row mb-12 md:mb-16">
                            <div className="w-full md:w-1/2 px-4 flex flex-col justify-center mb-6 md:mb-0">
                                <h3 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 md:mb-6">Compete</h3>
                                <p className="text-left text-base md:text-lg lg:text-xl">
                                    Compete with friends and track your progress on the leaderboard. Avoid the Hall of Shame!
                                </p>
                            </div>
                            <div className="w-full md:w-1/2 px-4 flex items-center justify-center">
                                <div className="w-full max-w-lg h-auto overflow-hidden rounded-lg shadow-lg transition-all duration-300 hover:scale-150">
                                    <img src={competeGraphic} alt="OniKanji Leaderboard Highscore Graphic" className="w-full h-auto" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonials Section */}
                <div className="w-full py-8 md:py-16 relative flex justify-center items-center bg-gradient-to-br from-logoColor via-[#1a7fa3] to-[#015f80] overflow-hidden">
                    <div className="absolute inset-0 opacity-10">
                        <svg className="w-full h-full" viewBox="0 0 100 100" preserveAspectRatio="none">
                            <path d="M0,50 Q25,30 50,50 T100,50 T150,50" fill="none" stroke="white" strokeWidth="0.5">
                                <animate 
                                    attributeName="d" 
                                    dur="5s" 
                                    repeatCount="indefinite"
                                    values="M0,50 Q25,30 50,50 T100,50 T150,50;
                                            M0,50 Q25,70 50,50 T100,50 T150,50;
                                            M0,50 Q25,30 50,50 T100,50 T150,50"
                                />
                            </path>
                            <path d="M0,70 Q25,50 50,70 T100,70 T150,70" fill="none" stroke="white" strokeWidth="0.5">
                                <animate 
                                    attributeName="d" 
                                    dur="7s" 
                                    repeatCount="indefinite"
                                    values="M0,70 Q25,50 50,70 T100,70 T150,70;
                                            M0,70 Q25,90 50,70 T100,70 T150,70;
                                            M0,70 Q25,50 50,70 T100,70 T150,70"
                                />
                            </path>
                            <path d="M0,30 Q25,10 50,30 T100,30 T150,30" fill="none" stroke="white" strokeWidth="0.5">
                                <animate 
                                    attributeName="d" 
                                    dur="6s" 
                                    repeatCount="indefinite"
                                    values="M0,30 Q25,10 50,30 T100,30 T150,30;
                                            M0,30 Q25,50 50,30 T100,30 T150,30;
                                            M0,30 Q25,10 50,30 T100,30 T150,30"
                                />
                            </path>
                        </svg>
                    </div>
                    <div className="absolute inset-0 bg-black bg-opacity-20"></div>
                    <div className="relative z-10 w-full md:w-4/5 lg:w-4/5 xl:w-4/5 p-2">
                        <h2 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold text-white mb-6 md:mb-8 text-center px-4">
                            What Our Users Are Saying
                        </h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 w-full max-w-5xl px-4 mx-auto">
                            {/* Testimonial 1 */}
                            <div className="bg-white p-4 md:p-6 lg:p-8 rounded-lg shadow-lg flex flex-col justify-between transform transition-transform duration-300 hover:-translate-y-2">
                                <p className="text-sm md:text-base lg:text-xl mb-3 lg:mb-4 text-gray-700">
                                    "Onikanji's learning in context, makes vocabulary a first class citizen with each Kanji you learn, as well as a variety of tools to understand and deepen your study, makes this the most effective tool to learn Kanji and Japanese vocabulary I've found."
                                </p>
                                <p className="font-semibold text-sm md:text-base lg:text-lg text-logoColor">
                                    - Joao P., N5 Level Student
                                </p>
                            </div>

                            {/* Testimonial 2 */}
                            <div className="bg-white p-4 md:p-6 lg:p-8 rounded-lg shadow-lg flex flex-col justify-between transform transition-transform duration-300 hover:-translate-y-2">
                                <p className="text-sm md:text-base lg:text-xl mb-3 lg:mb-4 text-gray-700">
                                    "Onikanji is an app designed to help you efficiently learn kanji readings, meanings, and usage. In today's digital world, knowing how to read and use kanji is often enough, and Onikanji makes mastering this easy."
                                </p>
                                <p className="font-semibold text-sm md:text-base lg:text-lg text-logoColor">
                                    - Yosuke Y., Native Japanese Speaker
                                </p>
                            </div>

                            {/* Testimonial 3 */}
                            <div className="bg-white p-4 md:p-6 lg:p-8 rounded-lg shadow-lg flex flex-col justify-between transform transition-transform duration-300 hover:-translate-y-2">
                                <p className="text-sm md:text-base lg:text-xl mb-3 lg:mb-4 text-gray-700">
                                    "What I like the most about OniKanji is that I'm not only learning Kanji, but also useful vocabulary."
                                </p>
                                <p className="font-semibold text-sm md:text-base lg:text-lg text-logoColor">
                                    - Klaas D., N5 Level Student
                                </p>
                            </div>
                        </div>

                        <div className="text-center mt-8">
                            <a 
                                href="https://discord.gg/7TEG9cqZTP" 
                                target="_blank" 
                                rel="noopener noreferrer" 
                                className="inline-block bg-[#5865F2] hover:bg-[#4752C4] text-white font-bold text-base md:text-lg py-2 px-4 md:px-6 lg:px-8 rounded-lg transition duration-300 shadow-lg hover:shadow-xl transform hover:-translate-y-1"
                            >
                                Join the Discord Community
                            </a>
                        </div>
                    </div>
                </div>

                                {/* New Godzilla Section with Updated Logic */}
                                <div className="w-full py-8 md:py-16 relative flex justify-center items-center">
                    <div className="relative w-full md:w-4/5 lg:w-4/5 xl:w-4/5 border-4 border-logoColor overflow-hidden rounded-lg">
                        <picture>
                            <source
                                media="(max-width: 768px)"
                                srcSet={godzillaJoinUsImageMobile}
                            />
                            <source
                                media="(min-width: 769px)"
                                srcSet={godzillaJoinUsImage}
                            />
                            <img
                                src={godzillaJoinUsImage} // Fallback image
                                alt="Join Us Godzilla"
                                className="w-full h-auto object-contain"
                            />
                        </picture>
                        <div className="absolute inset-0 flex items-center p-4 md:p-8">
                            <div className="w-full md:w-1/2 text-left bg-black bg-opacity-85 p-6 rounded-lg">
                                <h2 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold text-white mb-2 md:mb-4 leading-tight">
                                    Kanji is Tough—Let's Conquer it Together
                                </h2>
                                <p className="text-lg md:text-xl lg:text-2xl xl:text-3xl text-white mb-4 md:mb-6 leading-relaxed">
                                    We handle the complexity, so you can focus on learning.
                                    Start free today.
                                </p>
                                <button
                                    className="bg-logoColor hover:bg-[#015f80] text-white font-bold text-base md:text-lg py-2 px-4 rounded-lg transition duration-300 transform hover:scale-105"
                                    onClick={() => window.location.href = '/signup'}
                                >
                                    Begin Your Journey
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Pricing Section */}
                <div id="pricing-section" className="w-full py-12 md:py-20 px-4 md:px-8 bg-mainBackground">
                    <div className="max-w-6xl mx-auto">
                        <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold text-logoColor mb-8 md:mb-12 text-center">
                            OniKanji Alpha Launch Pricing
                        </h2>

                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 bg-blueBoxShadow rounded-lg p-4 md:p-8">
                            {/* Monthly Plan */}
                            <div className="bg-white p-8 border-2 border-gray-200 rounded-lg shadow-md flex flex-col h-full transition-all duration-300 hover:shadow-xl hover:scale-105">
                                <div className="mb-6">
                                    <div className="aspect-w-16 aspect-h-9 relative">
                                        <img 
                                            src={pricingFishImage}
                                            alt="Monthly Plan" 
                                            className="w-full h-full object-contain rounded-lg" 
                                        />
                                    </div>
                                </div>
                                <h3 className="text-2xl font-bold text-logoColor mb-4">Monthly</h3>
                                <p className="text-4xl font-bold mb-2">$9.00<span className="text-lg font-normal">/month</span></p>
                                <p className="text-lg font-normal mb-2">Billed monthly</p>
                                <p className="text-sm text-green-600 mb-6">Most flexible option</p>
                                <ul className="mb-8 flex-grow space-y-3">
                                    <li className="flex items-center">
                                        <span className="text-green-500 mr-2">✓</span>
                                        Perfect for exploring and learning at your pace
                                    </li>
                                    <li className="flex items-center">
                                        <span className="text-green-500 mr-2">✓</span>
                                        Full access to all features
                                    </li>
                                    <li className="flex items-center">
                                        <span className="text-green-500 mr-2">✓</span>
                                        Cancel anytime
                                    </li>
                                    <li className="flex items-center">
                                        <span className="text-green-500 mr-2">✓</span>
                                        Most flexible option
                                    </li>
                                </ul>
                                <a 
                                    href="/signup"
                                    className="bg-logoColor hover:bg-[#015f80] text-white font-bold text-lg py-3 px-6 rounded-lg transition duration-300 w-full text-center"
                                >
                                    Get Started Free
                                </a>
                            </div>

                            {/* Yearly Plan */}
                            <div className="bg-white p-8 border-2 border-gray-200 rounded-lg shadow-md flex flex-col h-full transition-all duration-300 hover:shadow-xl hover:scale-105">
                                <div className="mb-6">
                                    <div className="aspect-w-16 aspect-h-9 relative">
                                        <img 
                                            src={pricingBonsaiImage}
                                            alt="Yearly Plan" 
                                            className="w-full h-full object-contain rounded-lg" 
                                        />
                                    </div>
                                </div>
                                <h3 className="text-2xl font-bold text-logoColor mb-4">Yearly</h3>
                                <p className="text-4xl font-bold mb-2">$7.00<span className="text-lg font-normal">/month</span></p>
                                <p className="text-2xl font-bold mb-2">$84.00<span className="text-base font-normal">/year</span></p>
                                <p className="text-sm text-gray-600 mb-6">Annual commitment required</p>
                                <ul className="mb-8 flex-grow space-y-3">
                                    <li className="flex items-center">
                                        <span className="text-green-500 mr-2">✓</span>
                                        For those who prefer annual billing
                                    </li>
                                    <li className="flex items-center">
                                        <span className="text-green-500 mr-2">✓</span>
                                        Full access to all features
                                    </li>
                                    <li className="flex items-center">
                                        <span className="text-green-500 mr-2">✓</span>
                                        Save $24 annually
                                    </li>
                                    <li className="flex items-center">
                                        <span className="text-green-500 mr-2">✓</span>
                                        Best value for committed learners
                                    </li>
                                </ul>
                                <a 
                                    href="/signup"
                                    className="bg-logoColor hover:bg-[#015f80] text-white font-bold text-lg py-3 px-6 rounded-lg transition duration-300 w-full text-center"
                                >
                                    Get Started Free
                                </a>
                            </div>

                            {/* Lifetime Plan */}
                            <div className="bg-white p-8 border-2 border-gray-200 rounded-lg shadow-md flex flex-col h-full transition-all duration-300 hover:shadow-xl hover:scale-105">
                                <div className="mb-6">
                                    <div className="aspect-w-16 aspect-h-9 relative">
                                        <img 
                                            src={pricingOniMaskImage}
                                            alt="Lifetime Plan" 
                                            className="w-full h-full object-contain rounded-lg" 
                                        />
                                    </div>
                                </div>
                                <h3 className="text-2xl font-bold text-logoColor mb-4">Lifetime</h3>
                                <p className="text-4xl font-bold mb-2">
                                    <span className="line-through">$290.00</span>
                                    <span className="ml-2">$190.00</span>
                                </p>
                                <p className="text-lg font-normal mb-2">One Time Charge</p>
                                <p className="text-sm text-gray-600 mb-6">Limited time offer</p>
                                <ul className="mb-8 flex-grow space-y-3">
                                    <li className="flex items-center">
                                        <span className="text-green-500 mr-2">✓</span>
                                        For dedicated lifetime learners
                                    </li>
                                    <li className="flex items-center">
                                        <span className="text-green-500 mr-2">✓</span>
                                        Full access to all features
                                    </li>
                                    <li className="flex items-center">
                                        <span className="text-green-500 mr-2">✓</span>
                                        Discord Community Role
                                    </li>
                                    <li className="flex items-center">
                                        <span className="text-green-500 mr-2">✓</span>
                                        Exclusive early access to new features
                                    </li>
                                    <li className="flex items-center">
                                        <span className="text-green-500 mr-2">✓</span>
                                        Limited time offer
                                    </li>
                                </ul>
                                <a 
                                    href="/signup"
                                    className="bg-logoColor hover:bg-[#015f80] text-white font-bold text-lg py-3 px-6 rounded-lg transition duration-300 w-full text-center"
                                >
                                    Get Started Free
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Footer />

            <style jsx>{`
  .backgroundImage {
    background-image: url(${backgroundImage});
    background-repeat: repeat;
    opacity: 0.1;
  }
`}</style>

        </div>
    );
};

export default OniKanjiLandingPage;
