import React from 'react';
import { BiVolumeFull } from "react-icons/bi";
import { generateSSMLText } from '../../utils/generateSSML';

export function formatFuriganaText(
  text, 
  handleTextToSpeech, 
  isAudioPlaying, 
  handleSentenceClick, 
  handleKanjiClick,
  showFurigana = true,  // default value
  line,               // optional transcript line object (provided for podcasts)
  isPodcast = false   // optional flag; if true, TTS button is omitted
) {
    if (!text) return text;

    // Split by HTML tags while preserving them
    const htmlParts = text.split(/(<[^>]*>)/);
    
    // Initialize variables to track sentences
    let currentSentence = [];
    let sentenceCount = 0;

    const processSentence = (parts, isEndOfText = false) => {
        const elements = [];
        const fullSentence = currentSentence.join('');
        const sentenceId = `sentence-${sentenceCount}`;
        
        // Add TTS button at the start of the sentence only when not in podcast mode.
        if (!isPodcast) {
          elements.push(
              <button
                  key={`tts-${sentenceId}`}
                  className={`
                      mx-2 text-blue-500 hover:text-blue-700 
                      transition-colors duration-200
                      ${isAudioPlaying ? 'opacity-50 cursor-not-allowed' : ''}
                  `}
                  onClick={(e) => {
                      e.stopPropagation();
                      if (!isAudioPlaying) {
                          const ssmlText = generateSSMLText(fullSentence);
                          handleTextToSpeech(ssmlText);
                      }
                  }}
                  disabled={isAudioPlaying}
              >
                  <BiVolumeFull className="w-6 h-6" />
              </button>
          );
        }
        
        // Process the rest of the sentence (rendering furigana and clickable kanji)
        for (let i = 0; i < parts.length; i++) {
            // Every even-indexed part is the base text
            if (i % 2 === 0) {
                if (!parts[i + 1]) {
                    elements.push(
                        <span key={`text-${i}`}>
                            {parts[i]}
                        </span>
                    );
                } else {
                    const lastKanji = parts[i].match(/[\u4E00-\u9FAF]+$/)?.[0] || parts[i];
                    const prefix = parts[i].slice(0, -lastKanji.length);
                    
                    if (prefix) {
                        elements.push(
                            <span key={`prefix-${i}`}>
                                {prefix}
                            </span>
                        );
                    }
                    
                    if (showFurigana) {
                      elements.push(
                          <ruby key={`ruby-${i}`} className="hover:bg-purple-200 cursor-pointer">
                              <rb 
                                className="not-audio"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleKanjiClick(lastKanji, e);
                                }}
                              >
                                {lastKanji}
                              </rb>
                              <rt>{parts[i + 1]}</rt>
                          </ruby>
                      );
                    } else {
                      elements.push(
                          <span 
                            key={`ruby-${i}`}
                            className="not-audio hover:bg-purple-200 cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleKanjiClick(lastKanji, e);
                            }}
                          >
                            {lastKanji}
                          </span>
                      );
                    }
                }
            }
        }

        // For both podcast and non-podcast, wrap the sentence in a clickable span.
        // When in podcast mode, pass the line along with the sentence.
        return (
            <span 
                key={sentenceId} 
                className="not-audio items-center mb-1 leading-relaxed cursor-pointer hover:bg-green-200"
                onClick={(e) => {
                    e.stopPropagation();
                    if (isPodcast && line) {
                      handleSentenceClick(fullSentence, line, e);
                    } else {
                      handleSentenceClick(fullSentence, e);
                    }
                }}
            >
                {elements}
            </span>
        );
    };

    return htmlParts.map((part, index) => {
        if (part.startsWith('<') && part.endsWith('>')) {
            return <span key={`html-${index}`} dangerouslySetInnerHTML={{ __html: part }} />;
        }
        
        if (part.includes('//')) {
            const quoteParts = part.split(/(「[^」]*」)/);
            
            let sentences = [];
            quoteParts.forEach(quotePart => {
                if (quotePart.startsWith('「') && quotePart.endsWith('」')) {
                    sentences.push(quotePart);
                } else {
                    const splitSentences = quotePart.split(/([。！？])/);
                    sentences = sentences.concat(splitSentences);
                }
            });
            
            return sentences.map((sentence, idx) => {
                if (sentence === '') return null;
                
                if (sentence.match(/[。！？]/)) {
                    currentSentence.push(sentence);
                    const processedSentence = processSentence(currentSentence.join('').split('//'));
                    currentSentence = [];
                    sentenceCount++;
                    return processedSentence;
                }
                
                currentSentence.push(sentence);
                
                if (idx === sentences.length - 1) {
                    const processedSentence = processSentence(currentSentence.join('').split('//'), true);
                    currentSentence = [];
                    sentenceCount++;
                    return processedSentence;
                }
                
                return null;
            });
        }
        
        return (
            <span key={`text-${index}`}>
                {part}
            </span>
        );
    });
}
