import React, { useEffect } from 'react';
import { useLocation, useNavigate, useNavigationType } from 'react-router-dom';
import { useUser } from '../components/utils/UserContext';
import MainQuizEngine from '../quizEngine/mainQuizEngine';

function KanjiReviewPage() {
  const navigate = useNavigate();
  const navigationType = useNavigationType();
  const location = useLocation();
  const { user, loading: userLoading, subscriptionStatus } = useUser();

  // "reviews" passed in from another page via React Router location.state
  const reviews = location.state?.reviews || [];

  // Make sure the user is logged in, etc.
  useEffect(() => {
    document.title = "OniKanji - Kanji Reviews";
    if (!userLoading) {
      if (!user) {
        navigate('/login');
      } else if (navigationType !== 'PUSH') {
        // If someone forcibly typed URL or refresh => redirect them
        navigate('/home');
      }
    }
  }, [user, userLoading, navigate, navigationType]);


  return (
    <div className="bg-mainBackgroundColor min-h-screen flex flex-col font-noto-sans-jp">
      <MainQuizEngine
        quizData={reviews}
        saveProgressEnabled={true}
        showFlashcardEnabled={false}
        previousPath="/reviews/"
      />
    </div>
  );
}

export default KanjiReviewPage;

