// src/components/LevelsNavDropDown.js

import React, { useState, useRef, useEffect } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { BiXCircle } from "react-icons/bi";
import { Link } from 'react-router-dom';

const LevelsNavDropDown = () => {
    const levelsPopupRef = useRef(null);
    const [showLevelsPopup, setShowLevelsPopup] = useState(false);
    const [expandedLevels, setExpandedLevels] = useState(false);
    const [showInfoPopup, setShowInfoPopup] = useState(true);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (levelsPopupRef.current && !levelsPopupRef.current.contains(event.target)) {
                setShowLevelsPopup(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const LevelSquare = ({ level }) => {
        const isCompleted = level <= 22;
        return (
            <Link to={`/levels/${level}`}>
                <div 
                    className={`w-8 h-8 m-2 flex items-center justify-center cursor-pointer rounded text-xs font-semibold ${
                        isCompleted 
                            ? 'bg-lightBlueBackground text-lightBlueText hover:bg-darkBlueColor' 
                            : 'bg-gray-200 hover:bg-darkOrangeColor hover:text-white'
                    }`}
                >
                    {level}
                </div>
            </Link>
        );
    };

    return (
        <div className="relative" ref={levelsPopupRef}>
            <button 
                className="px-4 py-2 rounded hover:underline focus:outline-none font-semibold"
                onClick={() => setShowLevelsPopup(!showLevelsPopup)}
            >
                Levels
            </button>
            {showLevelsPopup && (
                <div 
                    className="absolute left-1/2 transform -translate-x-1/2 z-50 mt-2 p-4 bg-white rounded-lg shadow-xl"
                    style={{ maxHeight: '80vh', overflowY: 'auto', width: '300px' }}
                >
                    {showInfoPopup && (
                        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center z-10">
                            <div className="bg-white p-6 rounded-lg relative max-w-xs">
                                <BiXCircle 
                                    className="absolute top-3 right-3 text-2xl cursor-pointer text-gray-600 hover:text-gray-800"
                                    onClick={() => setShowInfoPopup(false)}
                                />
                                <p className="text-center text-base leading-relaxed">
                                    Levels are under rapid development 🚀
                                    <br />
                                    Follow on <a href="https://discord.gg/7TEG9cqZTP" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">Discord</a> for updates.
                                </p>
                            </div>
                        </div>
                    )}
                    <div className="grid grid-cols-6 gap-2 justify-items-center">
                        {[...Array(expandedLevels ? 120 : 60)].map((_, index) => (
                            <LevelSquare key={index} level={index + 1} />
                        ))}
                    </div>
                    <div className="flex justify-center mt-4">
                        {expandedLevels ? (
                            <BsChevronUp
                                className="cursor-pointer text-2xl"
                                onClick={() => setExpandedLevels(false)}
                            />
                        ) : (
                            <BsChevronDown
                                className="cursor-pointer text-2xl"
                                onClick={() => setExpandedLevels(true)}
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default LevelsNavDropDown;
