import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/navBar';
import { useUser } from '../components/utils/UserContext';
import Footer from '../components/footer';

function AboutUsPage() {
    const navigate = useNavigate();
    const { user, loading: userLoading, subscriptionStatus } = useUser();

    useEffect(() => {
        document.title = "OniKanji - About Us";
    }, []);

    const teamMembers = [
        {
            name: "Khalid F.",
            role: "Founder & Lead Developer",
            bio: "With 10 years of experience in data science, algorithmic development, and technology, Khalid combined his professional expertise with his passion for language learning and interest in Japanese. He created OniKanji to provide a new learning algorithm and ed tech platform that serves as a guide to accelerate kanji learning for students worldwide.",
            image: "https://firebasestorage.googleapis.com/v0/b/kf-demo-project-b0508.appspot.com/o/assets%2FaboutUsAssets%2Fkf_headshot.jpg?alt=media&token=9cf3ba94-32a1-4d39-ba43-11c09c1dca6a",
            socialLinks: {
                linkedin: "https://www.linkedin.com/in/khalid-foflonker-6a942783/"
            }
        },
        {
            name: "Yosuke Y.",
            role: "Head of Curriculum",
            bio: "A native Japanese speaker from Aichi Prefecture with a master's degree from Tokyo Institute of Technology, Yosuke brings both technical expertise and teaching experience to OniKanji. After a successful career at Recruit Co., Ltd. in leadership roles, he now dedicates himself to Japanese language education through his popular YouTube channel with 30,000+ subscribers, creating engaging content that makes learning Japanese accessible and enjoyable.",
            image: "https://firebasestorage.googleapis.com/v0/b/kf-demo-project-b0508.appspot.com/o/assets%2FaboutUsAssets%2FYosuke_Teaches_Japanese_Headshot.png?alt=media&token=f4b2463d-5858-47a4-b66f-59eeb580673b",
            socialLinks: {
                youtube: "https://www.youtube.com/@YosukeTeachesJapanese"
            }
        }
    ];

    return (
        <div className="font-noto-sans-jp">
            <Navbar user={user} subscriptionStatus={subscriptionStatus} />

            <div className="bg-mainBackgroundColor min-h-screen flex flex-col items-center p-4">
                {/* Hero Section */}
                <div className="w-full max-w-screen-xl bg-white shadow-blueBoxShadow rounded-lg p-6 sm:p-10 mt-10">
                    <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold text-logoColor mb-6 text-center">
                        Meet the OniKanji Team
                    </h1>
                    <p className="text-lg sm:text-xl text-gray-700 mb-8 text-center max-w-3xl mx-auto">
                        We're a passionate group of language enthusiasts, educators, and technologists dedicated to expanding the pool of kanji learning resources for everyone.
                    </p>
                    
                    {/* Team Members Section */}
                    <div className="mt-8">
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                            {teamMembers.map((member, index) => (
                                <div 
                                    key={index} 
                                    className="bg-gray-50 rounded-lg overflow-hidden shadow-md transition-all duration-300 hover:shadow-xl hover:-translate-y-1 flex flex-col lg:flex-row"
                                >
                                    <div className="lg:w-2/5 h-80 lg:h-auto overflow-hidden">
                                        <img 
                                            src={member.image} 
                                            alt={member.name} 
                                            className="w-full h-full object-cover object-center transition-transform duration-300 hover:scale-105"
                                        />
                                    </div>
                                    <div className="lg:w-3/5 p-6">
                                        <h3 className="text-2xl font-bold text-logoColor">{member.name}</h3>
                                        <p className="text-darkOrangeColor font-semibold mb-3 text-lg">{member.role}</p>
                                        <p className="text-gray-700 text-base mb-4">{member.bio}</p>
                                        
                                        <div className="flex space-x-4">
                                            {member.socialLinks.twitter && (
                                                <a href={member.socialLinks.twitter} target="_blank" rel="noopener noreferrer" className="text-logoColor hover:text-logoColorHover">
                                                    <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                                        <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"></path>
                                                    </svg>
                                                </a>
                                            )}
                                            {member.socialLinks.linkedin && (
                                                <a href={member.socialLinks.linkedin} target="_blank" rel="noopener noreferrer" className="text-logoColor hover:text-logoColorHover">
                                                    <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                                        <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"></path>
                                                    </svg>
                                                </a>
                                            )}
                                            {member.socialLinks.github && (
                                                <a href={member.socialLinks.github} target="_blank" rel="noopener noreferrer" className="text-logoColor hover:text-logoColorHover">
                                                    <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                                        <path fillRule="evenodd" d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z" clipRule="evenodd"></path>
                                                    </svg>
                                                </a>
                                            )}
                                            {member.socialLinks.dribbble && (
                                                <a href={member.socialLinks.dribbble} target="_blank" rel="noopener noreferrer" className="text-logoColor hover:text-logoColorHover">
                                                    <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                                        <path fillRule="evenodd" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm6.605 4.61a8.502 8.502 0 011.93 5.314c-.281-.054-3.101-.629-5.943-.271-.065-.141-.12-.293-.184-.445a25.416 25.416 0 00-.564-1.236c3.145-1.28 4.577-3.124 4.761-3.362zM12 3.475c2.17 0 4.154.813 5.662 2.148-.152.216-1.443 1.941-4.48 3.08-1.399-2.57-2.95-4.675-3.189-5A8.687 8.687 0 0112 3.475zm-3.633.803a53.896 53.896 0 013.167 4.935c-3.992 1.063-7.517 1.04-7.896 1.04a8.581 8.581 0 014.729-5.975zM3.453 12.01v-.26c.37.01 4.512.065 8.775-1.215.25.477.477.965.694 1.453-.109.033-.228.065-.336.098-4.404 1.42-6.747 5.303-6.942 5.629a8.522 8.522 0 01-2.19-5.705zM12 20.547a8.482 8.482 0 01-5.239-1.8c.152-.315 1.888-3.656 6.703-5.337.022-.01.033-.01.054-.022a35.318 35.318 0 011.823 6.475 8.4 8.4 0 01-3.341.684zm4.761-1.465c-.086-.52-.542-3.015-1.659-6.084 2.679-.423 5.022.271 5.314.369a8.468 8.468 0 01-3.655 5.715z" clipRule="evenodd"></path>
                                                    </svg>
                                                </a>
                                            )}
                                            {member.socialLinks.youtube && (
                                                <a href={member.socialLinks.youtube} target="_blank" rel="noopener noreferrer" className="text-logoColor hover:text-logoColorHover">
                                                    <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                                        <path d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z" />
                                                    </svg>
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {/* Join Our Team Section */}
                <div className="w-full max-w-screen-xl bg-logoColor shadow-lg rounded-lg p-6 sm:p-10 mt-8 mb-16 text-white">
                    <div className="flex flex-col md:flex-row items-center justify-between">
                        <div className="md:w-2/3 mb-6 md:mb-0">
                            <h2 className="text-2xl sm:text-3xl font-bold mb-4">Join Our Team</h2>
                            <p className="text-lg mb-4">
                                We're always looking for talented individuals who are passionate about language learning and technology.
                            </p>
                            <p className="text-lg">
                                If you're excited about revolutionizing how people learn kanji, we'd love to hear from you!
                            </p>
                        </div>
                        <div className="md:w-1/3 flex justify-center md:justify-end">
                            <a 
                                href="mailto:khalid@onikanji.com" 
                                className="bg-white text-logoColor hover:bg-gray-100 font-bold py-3 px-6 rounded-lg transition duration-300 text-center"
                            >
                                Send Us Your Resume
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />

            <style jsx>{`
                .shadow-text {
                    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
                }
            `}</style>
        </div>
    );
}

export default AboutUsPage;
