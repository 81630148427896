import React, { useMemo } from 'react';
import { ClipLoader } from 'react-spinners';
import { usePremadeLists } from './hooks/fetchPremadeLists';

export const PremadeListsDisplay = ({ 
  onSelectList, 
  selectedListId, 
  excludedQuestionTypes = [],
  excludedAnswerTypes = [] 
}) => {
  const { data: premadeLists, isLoading, error } = usePremadeLists();
  
  const adjustedPremadeLists = useMemo(() => {
    if (!premadeLists) return [];
    
    return premadeLists.map(list => {
      let adjustedCount = list.questionCount;
      
      if (excludedQuestionTypes.length > 0) {
        const reductionFactor = excludedQuestionTypes.length / 3;
        adjustedCount = Math.round(adjustedCount * (1 - reductionFactor));
      }
      
      if (excludedAnswerTypes.includes('Multiple Choice') && excludedAnswerTypes.includes('Fill in the Blank')) {
        adjustedCount = 0;
      } else if (excludedAnswerTypes.includes('Multiple Choice') || excludedAnswerTypes.includes('Fill in the Blank')) {
        adjustedCount = Math.round(adjustedCount * 0.5);
      }
      
      return {
        ...list,
        adjustedQuestionCount: Math.max(0, adjustedCount)
      };
    });
  }, [premadeLists, excludedQuestionTypes, excludedAnswerTypes]);
  
  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-[200px]">
        <ClipLoader color="#014156" className="mr-2" />
      </div>
    );
  }
  
  if (error) {
    return (
      <div className="text-red-600 p-4 text-center">
        <div className="font-bold mb-2">Error loading premade lists</div>
        <div className="text-sm">Please try refreshing the page</div>
      </div>
    );
  }
  
  if (!adjustedPremadeLists || adjustedPremadeLists.length === 0) {
    return (
      <div className="text-gray-600 p-4 text-center">
        No premade lists available at this time.
      </div>
    );
  }
  
  return (
    <div className="max-w-screen-lg w-full mx-auto">
      <div className="text-lg mb-4">
        Select a premade list to study
      </div>
      
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3">
        {adjustedPremadeLists.map(list => (
          <div 
            key={list.id}
            onClick={() => onSelectList(list)}
            className={`p-4 bg-white border rounded-lg shadow hover:bg-gray-50 cursor-pointer transition duration-200 
              ${selectedListId === list.id ? 'border-2 border-darkBlueColor' : 'border-gray-200'}`}
          >
            <h4 className="font-bold text-lg mb-1">{list.title}</h4>
            <div className="flex flex-wrap gap-1 mb-2">
              {list.genre && list.genre.slice(0, 3).map(tag => (
                <span key={tag} className="text-xs px-2 py-1 bg-gray-200 rounded-full mr-1">
                  {tag}
                </span>
              ))}
            </div>
            <div className="text-sm text-gray-600">
              {list.adjustedQuestionCount > 0 ? (
                <span className="flex items-center">
                  <span>{list.adjustedQuestionCount} questions</span>
                  {list.adjustedQuestionCount !== list.questionCount && (
                    <span className="text-xs text-gray-500 ml-1">
                      (filtered from {list.questionCount})
                    </span>
                  )}
                </span>
              ) : (
                <span className="text-amber-600">No questions match current filters</span>
              )}
              {list.kanjiCount > 0 && <span>{list.kanjiCount} kanji</span>}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};